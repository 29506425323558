import { useState,  Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { selectEnhancedUser } from '../../store/user/user.selector';
import { signOutUser } from '../../utils/firebase/firebase-auth.utils';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const Header = ({ window }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    
    const currentUser = useSelector(selectEnhancedUser);
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleSignOut = async () => {
        try {
            await signOutUser();
            navigate("/");
        } catch (error) {
            enqueueSnackbar("Der Abmeldevorgang ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("sign out failed: ", error);
        }
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                BAUTAGEBUCH WEGENER
            </Typography>
            <Divider />
            {
                currentUser ? (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("user/diary-overview")}>
                                <ListItemText primary="Bautagebücher" />
                            </ListItemButton>
                        </ListItem>
                        {
                            currentUser.admin ? (
                                <Fragment>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate("admin/projects")}>
                                            <ListItemText primary="Projekte" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate("admin/employees")}>
                                            <ListItemText primary="Monteure" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate("admin/users")}>
                                            <ListItemText primary="Benutzer" />
                                        </ListItemButton>
                                    </ListItem>
                                </Fragment>
                            ) : null
                        }
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleSignOut}>
                                <ListItemText primary="Abmelden" />
                            </ListItemButton>
                        </ListItem>                                        
                    </List>
                ) : null
            } 
        </Box>
    );
    
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box>
            <AppBar component="nav" position='sticky'>
                <Toolbar>
                    {
                        currentUser ? (
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { xs:'block', sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <Typography
                                sx={{ flexGrow: 1, display: { xs:'block', sm: 'none' }}}
                            >
                                BAUTAGEBUCH WEGENER
                            </Typography>
                        )
                    }                    

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        BAUTAGEBUCH WEGENER
                    </Typography>

                    {
                        currentUser ? (
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Button sx={{ color: '#fff' }} onClick={() => navigate("user/diary-overview")}>
                                    Bautagebücher
                                </Button>
                                { 
                                    currentUser.admin ? (
                                        <Fragment>  
                                            <Button sx={{ color: '#fff' }} onClick={() => navigate("admin/projects")}>
                                                Projekte
                                            </Button>
                                            <Button sx={{ color: '#fff' }} onClick={() => navigate("admin/employees")}>
                                                Monteure
                                            </Button>
                                            <Button sx={{ color: '#fff' }} onClick={() => navigate("admin/users")}>
                                                Benutzer
                                            </Button>
                                        </Fragment>
                                    ) : null
                                }

                                <Button sx={{ color: '#fff' }} onClick={handleSignOut}>
                                    Abmelden
                                </Button>
                            </Box>
                        ) : null
                    }
                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Header;
