import { useField } from 'formik';

import Textfield from "@mui/material/Textfield";

const FormikTextfield = ({ name, ...otherProps }) => {
    const [field, mata] = useField(name);

    const configTextfield = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined'
    };

    if(mata && mata.touched && mata.error)
    {
        configTextfield.error = true;
        configTextfield.helperText = mata.error;
    }

    return <Textfield {...configTextfield}/>;
}

export default FormikTextfield;