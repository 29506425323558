import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectActiveProjects, selectArchivedProjects } from '../../store/projects/projects.selector';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ProjectsList from '../../components/projects-list/projects-list.component';
import ProjectsListAddDialog from '../../components/projects-list/projects-list-add-dialog.component';

const Projects = () => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const activeProjects = useSelector(selectActiveProjects);
  const archivedProjects = useSelector(selectArchivedProjects);

  return (
    <Fragment>
      <Container>
        <Stack marginY={2} spacing={4} alignItems="center">        
            <ProjectsList title="Aktive Projekte" projects={activeProjects} isActiveProjectList={true} />
            <Box>
              <Button variant="contained" color="secondary" onClick={() => setIsAddDialogOpen(true)}>
                Neues Projekt
              </Button>
            </Box>  
            <ProjectsList title="Archivierte Projekte" projects={archivedProjects} isActiveProjectList={false}/>
        </Stack>
      </Container>
      <ProjectsListAddDialog
          open={isAddDialogOpen}
          handleClose={() => setIsAddDialogOpen(false)}
      /> 
    </Fragment>    
  );
}

export default Projects;
