import { Offline } from "react-detect-offline";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NoInternetIndicator = () => {
    return (
        <Offline>
            <Box sx={{ height: '48px', backgroundColor: "error.main", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography variant="h6" textAlign="center" >
                    KEIN INTERNET
                </Typography>
            </Box>
        </Offline>
    );
}

export default NoInternetIndicator;