import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUsers } from '../../store/users/users.selector';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import User from '../../components/user/user.component';
import UserAddDialog from '../../components/user/user-add-dialog.component';

const Users = () => {
    const users = useSelector(selectUsers);
    const [newUserDialog, setNewUserDialog] = useState(false);

    return (
        <Container>
            <Stack marginY={2} spacing={4} alignItems="center">
                <Typography variant="h6" textAlign="center">
                    Benutzer
                </Typography>
                <Box>
                    {
                        users.map((user, idx) => {
                            return <User key={idx} user={user}/>
                        })
                    }
                </Box>
                <Box>
                    <Button variant="contained" color="secondary" onClick={() => setNewUserDialog(true)}>
                        Benutzer hinzufügen
                    </Button>
                </Box>            
            </Stack>
            <UserAddDialog
                open={newUserDialog}
                handleClose={() => setNewUserDialog(false)}
            />
        </Container>
  );
}

export default Users;
