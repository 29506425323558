import { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';

import { deleteEntry } from '../../utils/firebase/firebase-firestore.utils';

import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import EmployeeEditDialog from './employee-edit-dialog.component';
import ConfirmDeclineDialog from '../confirm-decline-dialog/confirm-decline-dialog.component';

const EmployeesListItem = ({ 
    dialogTitle, 
    dialogLabelName, 
    collectionName,  
    employee 
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); 
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false); 

    const handleDelete = async () => {
        try {
            deleteEntry(collectionName, employee.fullName);
            setIsDeleteDialogOpen(false);
        } catch (error) {
            enqueueSnackbar("Das Löschen des Gesellen, Auszubildenen oder Fremdfirma ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("employee delete failed: ", error);
        }
    }

    return (
        <Fragment>
            <ListItem sx={{ backgroundColor: 'lightGrey' }}>
                <Typography marginRight='auto' variant="body1" component="div">
                    {employee.fullName}
                </Typography>
                <IconButton onClick={() => setIsEditDialogOpen(true)}>
                    <EditIcon />
                </IconButton>
                <IconButton color='error' onClick={() => setIsDeleteDialogOpen(true)}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>
            <EmployeeEditDialog
                open={isEditDialogOpen}
                handleClose={() => setIsEditDialogOpen(false)}
                title={dialogTitle}
                labelName={dialogLabelName}
                collectionName={collectionName}
                oldName={employee.fullName}
            />
            <ConfirmDeclineDialog
                open={isDeleteDialogOpen}
                dialogTitle="Eintrag löschen"
                dialogText="Sind Sie sich sicher, dass Sie diesen Eintrag löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden."
                confirmText="Löschen"
                declineText="Abbrechen"
                handleClose={() => setIsDeleteDialogOpen(false)}
                handleConfirmActiom={handleDelete}
                handleDeclineAction={() => setIsDeleteDialogOpen(false)}
            />
        </Fragment> 
    );
}

export default EmployeesListItem;
