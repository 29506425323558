import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { where } from "firebase/firestore";

import { addEntry, countDocs, FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import { uploadFile } from "../../utils/firebase/firebase-storage.utils";
import { addUser } from "../../utils/firebase/firebase-functions.utils";
import { generateUID } from "../../utils/uid";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from "@mui/material/Typography";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikSwitch from "../formik-switch/formik-switch.component";
import FormikImageSelection from "../formik-image/formik-image-selection.component";
import LoadingIndicator from "../loading/loading-indicator.component";

const INITIAL_FORM_STATE = {
    displayName: "",
    admin: false,
    email: "",
    password: "",
    confirmPassword: "",
    signatureImageFile: [],
};

const FORM_VALIDATION = Yup.object().shape({
    displayName: Yup.string().required("Erforderlich"),
    admin: Yup.bool().required("Erforderlich"),
    email: Yup.string().email("Keine Email-Adresse").required("Erforderlich"),
    password: Yup.string().min(6, "Mindestens 6 Zeichen").required("Erforderlich"),
    confirmPassword: Yup.string()
        .required("Erforderlich")
        .oneOf([Yup.ref('password'), null], 'Passwort stimmt nicht überein'),
    signatureImageFile: Yup.array().min(1).max(1).required(),
});

const UserAddDialog = ({ open, handleClose }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { resetForm }) => {
        const emailCount = await countDocs(FIREBASE_COLLECTION_NAMES.USERS, where('email', '==', values.email));
        if(emailCount > 0)
        {
            enqueueSnackbar("Die Email wird bereits verwendet.", { variant: 'error' });
            return;
        } 

        try {
            setIsLoading(true);
            const { displayName, admin, email, password, signatureImageFile } = values;

            const data = await addUser(email, password);

            const signatureUrl = await uploadFile(
                `/users/${email}/${generateUID()}.jpg`, 
                signatureImageFile[0].blob
            ) ;            

            addEntry(
                FIREBASE_COLLECTION_NAMES.USERS,
                email, 
                {
                    displayName,
                    email,
                    admin,
                    projectNumbers: [],
                    signatureUrl
            });            

            handleClose();
            resetForm();   
        } catch(error) {
            if(error.code === 'auth/email-already-in-use') {
                enqueueSnackbar("Die Email-Adresse ist bereits registriert.", { variant: 'info' });
            } else {
                enqueueSnackbar("Das Erstellen des Benutzers ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
                console.log("user creation failed: ", error);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            <Formik
                initialValues={{ ...INITIAL_FORM_STATE }}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
            >
                <Form> 
                    <Dialog 
                        open={open}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="xs"
                    >
                        <DialogTitle>Neuer Benutzer</DialogTitle>
                        <DialogContent>
                            <Stack 
                                spacing={2} 
                                marginY={2} 
                                alignItems="center"
                            >
                                <FormikTextfield name="displayName" fullWidth={true} label="Vor- und Nachname" variant="standard"/>
                                <FormControlLabel control={
                                    <FormikSwitch name="admin"/>
                                } label="Administrator" labelPlacement="start"  />
                                <FormikTextfield name="email" fullWidth={true} label="Email-Adresse" variant="standard"/>
                                <FormikTextfield name="password" fullWidth={true} label="Passwort" variant="standard" type="password"/>
                                <FormikTextfield name="confirmPassword" fullWidth={true} label="Passwort bestätigen" variant="standard" type="password"/>
                                <FormikImageSelection 
                                    name="signatureImageFile"
                                    multiple={false} 
                                    displayHeight={128}
                                    maxImageSize={800}
                                    uploadButtonText={"Unterschrift hochladen"}
                                />
                                <Typography variant="caption">
                                    Das empfohlene Seitenverhältnis ist 3:1 (bspw. 600 x 200 Pixel)
                                </Typography>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Abbrechen</Button>
                            <FormikButton>Speichern</FormikButton>
                        </DialogActions>
                    </Dialog>
                </Form>
            </Formik>
            <LoadingIndicator
                open={isLoading}
                text="Benutzer wird erstellt"
            />
        </Fragment>
    );
}

export default UserAddDialog;