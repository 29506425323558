import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { 
    selectDiaryProjectData, 
    selectDiarySiteLatLong, 
    selectDiaryWeatherDataRequested 
} from '../../store/diary/diary.selector';
import { selectDiaryOverviewNextDiaryNumber } from '../../store/diary-overview/diary-overview.selector';

import { 
    nextDiaryPage, 
    presetDiaryWeatherData, 
    previousDiaryPage, 
    resetDiaryWeatherData, 
    setDiaryProjectData 
} from '../../store/diary/diary.action';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; 

import FormikTextfield from '../formik-textfield/formik-textfield.component';
import StepperHeader from '../stepper/stepper-header.component';
import StepperFooter from '../stepper/stepper-footer.component';

const FORM_VALIDATION = Yup.object().shape({
    siteManagerClient: Yup.string().required("Erforderlich"),
}); 

const DiaryNewPage1 = ({ steps, activeStep }) => {
    const { enqueueSnackbar } = useSnackbar(); 
    const dispatch = useDispatch();

    const weatherDataRequested = useSelector(selectDiaryWeatherDataRequested);
    const siteLatLong = useSelector(selectDiarySiteLatLong);
    const diaryNumber = useSelector(selectDiaryOverviewNextDiaryNumber);
    const {
        date,
        week,
        projectNumber,
        beneficiary,
        siteStreetName,
        siteHouseNumber,
        siteZipCode,
        siteCityName,
        siteCountry,
        projectNumberClient,
        siteManager,
        siteManagerClient,
        craft,
    } = useSelector(selectDiaryProjectData);

    useEffect(() => {
        if(!weatherDataRequested) {
                fetch(`https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_API_KEY}&q=${siteLatLong.siteLong},${siteLatLong.siteLat}&aqi=no`)
                    .then(response => response.json())
                    .then(response => {
                            dispatch(presetDiaryWeatherData({
                                temp: response.current.temp_c,
                                wind: response.current.wind_kph,
                                cloud: response.current.cloud,
                                rain: response.current.precip_mm,
                            }));
                    })
                    .catch(error => {
                        dispatch(resetDiaryWeatherData());

                        enqueueSnackbar("Die Wetterdaten können nicht geladen werden.", { variant: 'info' });
                        console.log("weather api request failed: ", error);
                    });
        }        
    }, []);

    const handleBack = () => { 
        dispatch(previousDiaryPage());
    }
    
    const handleSubmit = (values) => {
        dispatch(setDiaryProjectData({ ...values, diaryNumber }));
        dispatch(nextDiaryPage());
    }

    return (
        <Formik
            initialValues={{ siteManagerClient }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <StepperHeader
                    activeStep={activeStep}
                    steps={steps}
                />
                <Grid container marginY={2} spacing={2}>
                    {/* Date */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Datum
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {date}
                        </Typography>
                    </Grid>
                    {/* CW */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Kalenderwoche
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {week}
                        </Typography>
                    </Grid>
                    {/* Bau-Tagesbericht-Nr. */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Bau-Tagesbericht-Nr.
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {diaryNumber}
                        </Typography>
                    </Grid>
                    {/* Leistungsempfänger */}
                    <Grid item xs={6} sm={3} minHeight={62} >
                        <Typography >
                            Leistungsempfänger
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {beneficiary}
                        </Typography>
                    </Grid>
                    {/* Bauleiter Wegener */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Bauleiter Wegener
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {siteManager}
                        </Typography>
                    </Grid>
                    {/* Bauleiter Auftraggeber */}
                    <Grid item xs={6} sm={3} height={62}>
                        <Typography >
                            Bauleiter Auftraggeber
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <FormikTextfield
                            name="siteManagerClient" 
                            fullWidth 
                            size="small" 
                            variant="outlined"
                        />
                    </Grid>
                    {/* Projektnummer Wegener */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Projektnummer Wegener
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {projectNumber}
                        </Typography>
                    </Grid>
                    {/* Baustelle, Anschrift */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Baustelle, Anschrift
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {`${siteStreetName} ${siteHouseNumber}, ${siteZipCode} ${siteCityName}, ${siteCountry}`}
                        </Typography>
                    </Grid>
                    {/* Projektnummer Auftraggeber */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Projektnummer Auftraggeber
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {projectNumberClient}
                        </Typography>
                    </Grid>
                    {/* Gewerk */}
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography >
                            Gewerk
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} minHeight={62}>
                        <Typography>
                            {craft}
                        </Typography>
                    </Grid>
                </Grid>
                <StepperFooter 
                    steps={steps}
                    activeStep={activeStep} 
                    handleBack={handleBack} 
                />
            </Form>
        </Formik>
    );
}

export default DiaryNewPage1;