const pica = require('pica')();

export const resizeImage = (imageFile, maxSize) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            const originalImage = new Image();
            originalImage.src = reader.result;

            originalImage.addEventListener("load", () => {
                let width = originalImage.width;
                let height = originalImage.height;
        
                if (width > height && width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                } else if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
        
                const resizedImage = document.createElement('canvas');
                resizedImage.width = width;
                resizedImage.height = height;
        
                pica.resize(originalImage, resizedImage)
                    .then(result => pica.toBlob(result, 'image/jpeg', 0.6))
                    .then(blob => resolve(blob))
                    .catch(error => console.log(`Image resize has failed: `, error));
            });
        })
        reader.readAsDataURL(imageFile);  
    }); 
}

export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        var reader = new FileReader();
        reader.addEventListener("load", () => {
            resolve(reader.result);  
        })
        reader.readAsDataURL(blob); 
    });
    
}