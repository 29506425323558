import { useField, useFormikContext } from "formik";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const FormikAutocomplete = ({ name, options, ...otherProps}) => {
    const [field, mata] = useField(name);
    const { values, setValues } = useFormikContext();

    const handleChange = (event, newValues) => {
        setValues({ ...values, [name]: newValues });
    }

    return (
        <Autocomplete
            {...otherProps}
            name={field.name}
            onChange={handleChange}
            multiple
            freeSolo  
            fullWidth 
            value={values[name]}
            options={options} 
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={field.name}
                    fullWidth
                    error={mata.touched && !!mata.error}
                    helperText={mata.touched && mata.error}
                    variant="outlined"
                />
            )}
        />
    )
}

export default FormikAutocomplete;