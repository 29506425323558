import { Fragment } from 'react';
import { useFormikContext } from 'formik';

import Button from '@mui/material/Button';

import FileImage from "../file-image/file-image.component";

const FormikImage = ({ name, displayHeight, image }) => {
    const { values, setValues } = useFormikContext();

    const deleteImage = () => {
        const newValues = values[name].filter(temp => temp.name !== image.name); 
        setValues({ ...values, [name]: newValues });
    }

    return (
        <Fragment>
            <FileImage image={image} displayHeight={displayHeight}/>
            <Button 
                fullWidth 
                disableElevation
                variant="contained" 
                style={{ borderRadius: 0 }}
                color="error" 
                onClick={deleteImage}
            >
                Löschen
            </Button>
        </Fragment>
    );
}

export default FormikImage;