import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { selectAuthStatus } from "../../store/user/user.selector";
import { USER_AUTH_STATES } from "../../store/user/user.types";

import { signInUserWithEmailAndPassword } from "../../utils/firebase/firebase-auth.utils";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import FormikButton from '../../components/formik-button/formik-button.component';
import FormikTextfield from '../../components/formik-textfield/formik-textfield.component';

const INITIAL_FORM_STATE = {
    email: "",
    password: "",
};

const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string().email("Keine Email-Adresse").required("Erforderlich"),
    password: Yup.string().required("Erforderlich"),
});

const Authentication = () => {
    const authStatus = useSelector(selectAuthStatus);
    const navigate = useNavigate();

    useEffect(() => {
        if(authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
            navigate("/user/diary-overview");
        }
    }, [authStatus])

    const handleSubmit = async (values, { resetForm }) => {
        const { email, password } = values;

        try {
            await signInUserWithEmailAndPassword(
                email, 
                password
            );
            resetForm();
        } catch(error) {
            switch(error.code)
            {
                case 'auth/wrong-password':
                case 'auth/user-not-found':
                    alert("Die Email-Adresse oder Passwort ist falsch."); 
                    break;
                default:
                    console.log(error);
            }
        }
    }

    if(authStatus !== USER_AUTH_STATES.SIGNED_OUT) {
        return;
    }

    return (
        <Grid
            container
            paddingY={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ flexGrow:1 }}
        >
            <Grid item xs={3}>
                <Paper elevation={2} sx={{ maxWidth: 500, padding: 4, margin: 4 }}>
                    <Typography variant="h6" component="div" textAlign="center">
                        Melde dich mit deiner Email-Adresse und Passwort an
                    </Typography>
                    <Formik
                        initialValues={{ ...INITIAL_FORM_STATE }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={handleSubmit}
                    >
                        <Form>                                                            
                            <Box mt={4}>
                                <FormikTextfield name="email" label="Email-Adresse" />
                            </Box>
                            <Box mt={2}>
                                <FormikTextfield name="password" type="password" label="Passwort" />
                            </Box>
                            <Box mt={2}>
                                <FormikButton size="large">
                                    Einloggen
                                </FormikButton>
                            </Box>                                
                        </Form>                           
                    </Formik>
                </Paper>                
            </Grid>
        </Grid> 
    );
}

export default Authentication;