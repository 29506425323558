import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from 'yup';

import { selectDiaryLoading, selectDiaryResults } from "../../store/diary/diary.selector";
import { 
    previousDiaryPage, 
    nextDiaryPage, 
    setDiaryResults, 
    setDiaryLoading
} from "../../store/diary/diary.action";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import StepperFooter from "../stepper/stepper-footer.component";
import StepperHeader from "../stepper/stepper-header.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikImageSelection from "../formik-image/formik-image-selection.component";
import LoadingIndicator from "../loading/loading-indicator.component";

const FORM_VALIDATION = Yup.object().shape({
    summary: Yup.string().required("Erforderlich"),
    comments: Yup.string(),
    specialEvents: Yup.string(),
    images: Yup.array(),
}); 

const DiaryNewPage4 = ({ steps, activeStep }) => {
    const dispatch = useDispatch();
    const loading = useSelector(selectDiaryLoading);
    const results = useSelector(selectDiaryResults);

    const handleBack = () => { 
        dispatch(previousDiaryPage());
    }
    
    const handleSubmit = (values) => {;
        dispatch(setDiaryResults(values));
        dispatch(nextDiaryPage());
    }  

    return (
        <Fragment>
            <Formik
                initialValues={{...results}}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
            >
                <Form>
                    <StepperHeader
                        activeStep={activeStep}
                        steps={steps}
                    />    
                    <Grid container marginY={2} spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6'>
                                Leistungsergebnisse/-änderungen
                            </Typography>                  
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormikTextfield name="summary" multiline/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6'>
                                Bemerkungen
                            </Typography>                  
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormikTextfield name="comments" multiline/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6'>
                                Besondere Vorkommnisse
                            </Typography>                  
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormikTextfield name="specialEvents" multiline/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6'>
                                Bilder
                            </Typography>                  
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormikImageSelection 
                                name="images" 
                                multiple={true}
                                displayHeight={211}
                                uploadButtonText={"Bilder hinzufügen"}
                                onStartSelection={() => dispatch(setDiaryLoading(true))}
                                onEndSelection={() => dispatch(setDiaryLoading(false))}
                            />
                        </Grid>
                    </Grid>  
                    <StepperFooter 
                        steps={steps}
                        activeStep={activeStep} 
                        handleBack={handleBack} 
                    /> 
                </Form>
            </Formik> 
            <LoadingIndicator
                open={loading}
                text="Bilder werden hinzugefügt"
            />
        </Fragment>
    );
}

export default DiaryNewPage4;