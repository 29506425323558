import { createSelector } from 'reselect';

const selectProjects = (state) => state.projects.projects;

export const selectActiveProjects = createSelector(
    [selectProjects],
    projects => projects.filter(project => !project.archived)
)

export const selectArchivedProjects = createSelector(
    [selectProjects],
    projects => projects.filter(project => project.archived)
)