import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { selectCraftsmen } from '../../store/craftsmen/craftsmen.selector';
import { selectApprentices } from '../../store/apprentices/apprentices.selector';
import { selectThirdParties } from '../../store/third-parties/third-parties.selector';
import { selectDiaryEmployees } from '../../store/diary/diary.selector';
import { 
    previousDiaryPage, 
    nextDiaryPage, 
    setDiaryEmployees 
} from '../../store/diary/diary.action';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import StepperHeader from '../stepper/stepper-header.component';
import StepperFooter from "../stepper/stepper-footer.component";
import FormikAutocomplete from '../formik-autocomplete/formik-autocomplete.component';

const FORM_VALIDATION = Yup.object().shape({
    craftsmen: Yup.array().of(
        Yup.string()
    ),
    apprentices: Yup.array().of(
        Yup.string()
    ),
    thirdParties: Yup.array().of(
        Yup.string()
    ),
}); 

const DiaryNewPage3 = ({ steps, activeStep }) => {
    const dispatch = useDispatch();

    const employees = useSelector(selectDiaryEmployees);
    const allCraftsmen = useSelector(selectCraftsmen).reduce((acc, craftsmen) => {
        acc.push(craftsmen.fullName);
        return acc;
    }, []);
    const allApprentices = useSelector(selectApprentices).reduce((acc, apprentice) => {
        acc.push(apprentice.fullName);
        return acc;
    }, []);
    const allThirdParties = useSelector(selectThirdParties).reduce((acc, thirdParty) => {
        acc.push(thirdParty.fullName);
        return acc;
    }, []);;

    const handleBack = () => { 
        dispatch(previousDiaryPage());
    }
    
    const handleSubmit = (values) => {
        dispatch(setDiaryEmployees(values));
        dispatch(nextDiaryPage());
    }    

    return (
        <Formik
            initialValues={{...employees}}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <StepperHeader
                    activeStep={activeStep}
                    steps={steps}
                />    
                <Grid container marginY={2} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6' mb={1}>
                            Gesellen
                        </Typography>                  
                        <FormikAutocomplete name="craftsmen" options={allCraftsmen}/>
                    </Grid>
                        <Grid item xs={12}>
                        <Typography variant='h6' mb={1}>
                            Auszubildene
                        </Typography>                  
                        <FormikAutocomplete name="apprentices" options={allApprentices}/>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography variant='h6' mb={1}>
                        Fremdfirmen
                    </Typography>                  
                    <FormikAutocomplete name="thirdParties" options={allThirdParties}/>
                    </Grid>
                </Grid>  
                <StepperFooter 
                    steps={steps}
                    activeStep={activeStep} 
                    handleBack={handleBack} 
                /> 
            </Form>
        </Formik> 
    );
}

export default DiaryNewPage3;