import { useField } from "formik";

import Checkbox from "@mui/material/Checkbox";

const FormikCheckbox = ({ name, otherProps }) => {
    const [field] = useField(name);

    const configCheckbox = {
        ...field,
        ...otherProps
    };

    return (
        <Checkbox
            {...configCheckbox}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
        />
    );
}

export default FormikCheckbox;