import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { addEntry, FIREBASE_COLLECTION_NAMES } from '../../utils/firebase/firebase-firestore.utils';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { Typography } from "@mui/material";

const FORM_VALIDATION = Yup.object().shape({
    projectNumber: Yup.string().required("Erforderlich"),
    beneficiary: Yup.string().required("Erforderlich"),
    siteStreetName: Yup.string().required("Erforderlich"),
    siteHouseNumber: Yup.string().required("Erforderlich"),
    siteZipCode: Yup.number("Keine Zahl").required("Erforderlich"),
    siteCityName: Yup.string().required("Erforderlich"),
    siteCountry: Yup.string().required("Erforderlich"),
    siteLong: Yup
        .number("Keine Zahl")
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .min(-180)
        .max(180)
        .required("Erforderlich"),
    siteLat: Yup
        .number("Keine Zahl")
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .min(-90)
        .max(90)
        .required("Erforderlich"),
    projectNumberClient: Yup.string().required("Erforderlich"),
    siteManagerClient: Yup.string().required("Erforderlich"),
    craft: Yup.string().required("Erforderlich"),
}); 

const ProjectsListEditDialog = ({ open, project, handleClose }) => { 
    const { enqueueSnackbar } = useSnackbar();
    
    const handleSubmit = async (values, { resetForm }) => {     
        try {
            addEntry(FIREBASE_COLLECTION_NAMES.PROJECTS, values.projectNumber, { 
                ...values,
                siteLat: values.siteLat.replace(',', '.'),
                siteLong: values.siteLong.replace(',', '.'),
                archived: false,
                diaries: [],            
            });
    
            handleClose();
            resetForm();
        } catch(error) {
            enqueueSnackbar("Das Bearbeiten des Projekts ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("project edit failed: ", error);
        }
    }

    return (
        <Formik
            initialValues={{ ...project }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form> 
                <Dialog 
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                >
                <DialogTitle>Projekt bearbeiten</DialogTitle>
                <DialogContent>
                        <Grid container spacing={2} marginY={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Wegener
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="projectNumber" fullWidth={true} label="Projektnummer Wegener" variant="standard" disabled/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="craft" fullWidth={true} label="Gewerk" variant="standard"/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginY={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Leistungsempfänger
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="beneficiary" fullWidth={true} label="Leistungsempfänger" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="projectNumberClient" fullWidth={true} label="Projektnummer Auftraggeber" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteManagerClient" fullWidth={true} label="Bauleiter Auftraggeber" variant="standard"/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginY={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Leistungsempfänger Addresse
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteStreetName" fullWidth={true} label="Straße" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteHouseNumber" fullWidth={true} label="Hausnummer" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteZipCode" fullWidth={true} label="Postleitzahl" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteCityName" fullWidth={true} label="Stadt" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteCountry" fullWidth={true} label="Land" variant="standard"/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginY={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Wetterabfrage
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteLong" fullWidth={true} label="Längengrad" variant="standard"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormikTextfield name="siteLat" fullWidth={true} label="Breitengrad" variant="standard"/>
                            </Grid>
                        </Grid>    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <FormikButton>Speichern</FormikButton>
                </DialogActions>
            </Dialog>
        </Form>
      </Formik>
    );
}

export default ProjectsListEditDialog;