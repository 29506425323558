import { useField, useFormikContext } from "formik";

import Grid from "@mui/material/Grid";

import FormikImage from "./formik-image.component";
import FormikFileButton from "./formik-file-button.component";

const FormikImageSelection = ({ 
    name, 
    multiple, 
    displayHeight,  
    uploadButtonText, 
    onStartSelection = () => { },
    onEndSelection = () => { }
}) => {
    const [field] = useField(name);
    const { values } = useFormikContext();

    return (
        <Grid 
            container
            spacing={multiple ? 2 : 0}
            textAlign="center"
        >            
            {
                values[name] && values[name].length > 0 ? (
                    values[name].map((image, idx) => {
                        return (
                            <Grid key={idx} item xs={12} sm={multiple ? 6 : 12}>
                                <FormikImage 
                                    key={idx} 
                                    name={name} 
                                    displayHeight={displayHeight}
                                    image={image} 
                                />
                            </Grid>
                        );
                    })
                ) : null                
            }
            {
                (!values[name] || values[name].length === 0) || multiple ? (
                    <Grid 
                        item 
                        height={displayHeight + 36.5}
                        xs={12} sm={multiple ? 6 : 12}
                    >
                        <FormikFileButton
                            name={field.name}
                            displayHeight={displayHeight}
                            multiple={multiple}
                            buttonText={uploadButtonText}
                            onStartSelection={onStartSelection}
                            onEndSelection={onEndSelection}
                        />
                    </Grid> 
                ) : null
            }                                                    
        </Grid>                 
    );
}

export default FormikImageSelection;