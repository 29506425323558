import { useState, Fragment } from 'react';

import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import EmployeesListItem from './employees-list-item.component';
import EmployeeAddDialog from './employee-add-dialog.component';

const EmployeesList = ({ 
    collectionName,
    title, 
    employees, 
    newButtonText, 
    newDialogTitle,
    editDialogTitle,
    dialogLabelName,
}) => {
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

    const openAddDialog = () => {
        setIsAddDialogOpen(true);
    }
    
    const closeAddDialog = () => {
        setIsAddDialogOpen(false);
    }  

    return (
        <Fragment>
            <Stack spacing={2}>
                <Typography variant="h6" component="h6" textAlign="center">
                    {title}
                </Typography>
                <List>
                    {
                        employees.map((employee, idx) => 
                            <EmployeesListItem 
                                key={idx}
                                dialogTitle={editDialogTitle} 
                                labelName={dialogLabelName} 
                                collectionName={collectionName}
                                employee={employee} 
                            />
                        )
                    }
                </List>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="secondary" onClick={openAddDialog}>
                        {newButtonText}
                    </Button>  
                </Box>
            </Stack>  
            <EmployeeAddDialog
                open={isAddDialogOpen}
                handleClose={closeAddDialog}
                title={newDialogTitle}
                labelName={dialogLabelName}
                collectionName={collectionName}
            /> 
        </Fragment>               
    );
}

export default EmployeesList;