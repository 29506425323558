import { Outlet } from 'react-router-dom';

import Box from "@mui/material/Box";

import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import NoInternetIndicator from '../../components/no-internet-indicator/no-internet-indicator.component';

const Navigation = () => {
  return (
    <Box sx={{ display:"flex", flexDirection:"column", height:"100vh" }}>
      <NoInternetIndicator/> 
      <Header/>  
      <Box sx={{ display:"flex", flexGrow:1 }}>
        <Outlet/>
      </Box>    
      <Footer/>
    </Box>
  );
}

export default Navigation;
