import { SnackbarProvider } from "notistack";

const ToastProvider = ({ children }) => {

    return (
        <SnackbarProvider 
            maxSnack={3} 
            autoHideDuration={5000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {children}
        </SnackbarProvider>
    );
}

export default ToastProvider;