export const DIARY_ACTION_TYPES = {
    RESET: 'diary/RESET',
    PREVIOUS_STEP: 'diary/PREVIOUS_STEP',
    NEXT_STEP: 'diary/NEXT_STEP',
    SET_PROJECT: 'diary/SET_PROJECT',
    SET_PROJECT_DATA: 'diary/SET_PROJECT_DATA',
    SET_WEATHER_DATA: 'diary/SET_WEATHER_DATA',
    SET_EMPLOYEES: 'diary/SET_EMPLOYEES',
    SET_RESULTS: 'diary/SET_RESULTS',
    SET_LOADING: 'diary/SET_LOADING',
}