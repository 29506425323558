import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { addEntry } from "../../utils/firebase/firebase-firestore.utils";

const FORM_VALIDATION = Yup.object().shape({
  fullName: Yup.string().required("Erforderlich"),
});

const EmployeeAddDialog = ({ open, handleClose, title, labelName, collectionName }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const { fullName } = values;

            addEntry(collectionName, fullName, { fullName });

            handleClose();
            resetForm();
        } catch (error) {
            enqueueSnackbar("Das Hinzufügen eines Gesellen, Auszubildenen oder Fremdfirma ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("adding employee failed: ", error);
        }
    }

    return (
        <Formik
            initialValues={{ fullName: "" }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form> 
                <Dialog 
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <Stack 
                            direction="column" 
                            spacing={2} 
                            marginY={2} 
                            alignItems="start"
                        >
                            <FormikTextfield name="fullName" fullWidth={true} label={labelName} variant="standard"/>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                        <FormikButton>Speichern</FormikButton>
                    </DialogActions>
                </Dialog>
            </Form>
        </Formik>
    );
}

export default EmployeeAddDialog;