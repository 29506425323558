import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { selectEnhancedUser } from "../../store/user/user.selector";

import { addEntry, FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikSwitch from "../formik-switch/formik-switch.component";

const FORM_VALIDATION = Yup.object().shape({
    fullName: Yup.string().required("Erforderlich"),
    admin: Yup.bool().required("Erforderlich")
});

const UserEditDialog = ({ open, handleClose, user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector(selectEnhancedUser);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const { fullName, admin } = values;

            addEntry(
                FIREBASE_COLLECTION_NAMES.USERS,
                user.email,
                {
                    displayName: fullName,
                    admin,
                }                    
            );

            handleClose();
            resetForm(); 
        } catch(error) {
            enqueueSnackbar("Das Bearbeiten des Benutzers ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("editing user failed: ", error);
        }             
    }

    return (
        <Formik
            initialValues={{ 
                fullName: user.displayName, 
                admin: user.admin, 
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form> 
                <Dialog 
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="xs"
                >
                <DialogTitle>Benutzer bearbeiten</DialogTitle>
                <DialogContent>
                    <Stack 
                        spacing={2} 
                        marginY={2} 
                        alignItems="center"
                    >
                        <FormikTextfield name="fullName" fullWidth={true} label="Vor- und Nachname" variant="standard"/>
                        <FormControlLabel control={
                            <FormikSwitch name="admin"/>
                        } label="Administrator" labelPlacement="start" fullWidth={true} disabled={currentUser.email === user.email} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <FormikButton>Speichern</FormikButton>
                </DialogActions>
            </Dialog>
        </Form>
      </Formik>
    );
}

export default UserEditDialog;