export const USER_AUTH_STATES = {
    UNKNOWN: 'UNKNOWN',
    SIGNED_IN_STARTED: 'SIGNED_IN_STARTED',
    SIGNED_IN_FINISHED: 'STANDARD_FINISHED',
    SIGNED_OUT: 'SIGNED_OUT',
}

export const USER_ACTION_TYPES = {
    SET_SIGN_IN_STARTED: 'user/SET_SIGN_IN_STARTED',
    SET_SIGN_IN_FINISHED: 'user/SET_SIGN_IN_FINISHED',
    SET_SIGNED_OUT: 'user/SET_SIGNED_OUT',
}