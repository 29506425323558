import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./loading-spinner.component";

const LoadingToRedirect = ({ redirectTo }) => {
  const [countdown, setCountdown] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((currentCount) => --currentCount);
    }, 1000);
    countdown === 0 && navigate(redirectTo);
    return () => clearInterval(interval);
  }, [countdown]);

  return <LoadingSpinner disableShrink/>;
};

export default LoadingToRedirect;