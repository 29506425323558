import { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { selectUsers } from '../../store/users/users.selector';

import { 
    addEntry, 
    deleteEntry, 
    FIREBASE_COLLECTION_NAMES 
} from '../../utils/firebase/firebase-firestore.utils';
import { deleteFolder } from '../../utils/firebase/firebase-storage.utils';
import { zipFilesFromUrl } from '../../utils/zip';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DownloadIcon from '@mui/icons-material/Download';

import ProjectsListEditDialog from './projects-list-edit-dialog.component';
import DiaryList from '../diary-list/diary-list.component';
import ConfirmDeclineDialog from '../confirm-decline-dialog/confirm-decline-dialog.component';

export const ProjectsListItem = ({ 
    project,
    expanded,
    openAccordion
}) => {
    const { enqueueSnackbar } = useSnackbar()

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); 
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);  

    const users = useSelector(selectUsers); 

    const siteManagers = users
        .filter(user => user.projectNumbers.includes(project.projectNumber))
        .map(user => user.displayName);
    let siteManagersText = "Nicht zugewiesen";
    if(siteManagers.length > 0) 
        siteManagersText = siteManagers.join(", ");

    const handleDownload = () => {
        const diaryUrls = [];
        project.diaries.forEach(diary => diaryUrls.push(diary.pdfUrl));
        zipFilesFromUrl(`Bautagebücher Projekt ${project.projectNumber}.zip`, diaryUrls);
    }

    const handleArchive = async () => {
        try {
            addEntry(FIREBASE_COLLECTION_NAMES.PROJECTS, project.projectNumber, {
                ...project,
                archived: true
            });
        } catch(error) {
            enqueueSnackbar("Das Archivieren des Projekts ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("project archiving failed: ", error);
        }
        
    }

    const handleUnarchive = async () => {
        try {
            addEntry(FIREBASE_COLLECTION_NAMES.PROJECTS, project.projectNumber, {
                ...project,
                archived: false
            });
        } catch(error) {
            enqueueSnackbar("Das Reaktivieren des Projekts ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("project unarchiving failed: ", error);
        }
    }

    const handleDelete = async () => {
        try {
            // Storage
            deleteFolder(`/projects/${project.projectNumber}`);

            // Database - Users
            const usersToEdit = users.filter(user => user.projectNumbers.includes(project.projectNumber));
            for(const user of usersToEdit) {
                const newUserProjects = user.projectNumbers.filter(userProjectNumber => userProjectNumber !== project.projectNumber); 
                await addEntry(FIREBASE_COLLECTION_NAMES.USERS, user.email, {
                    ...user,
                    projectNumbers: newUserProjects
                });
            }   

            // Database - Project
            await deleteEntry(FIREBASE_COLLECTION_NAMES.PROJECTS, project.projectNumber);

            setIsDeleteDialogOpen(false);
        } catch (error) {
            enqueueSnackbar("Das Löschen des Projekts ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("project deletion failed: ", error);
        }
    }

    return (
        <Fragment>
            <Accordion expanded={expanded === `${project.projectNumber}`} onChange={openAccordion(`${project.projectNumber}`)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>{`${project.projectNumber} - ${project.beneficiary}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="div">
                                        Projektdaten
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Projektnummer Wegener
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {project.projectNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Bauleiter Wegener
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {siteManagersText}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Leistungsempfänger
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {project.beneficiary}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Baustelle, Anschrift
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {`${project.siteStreetName} ${project.siteHouseNumber}, ${project.siteZipCode} ${project.siteCityName}, ${project.siteCountry}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Längen- und Breitengrad
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {`${project.siteLong.replace('.', ',')}° N, ${project.siteLat.replace('.', ',')}° O`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Projektnummer Auftragsgeber
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {project.projectNumberClient}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Bauleiter Auftragsgeber
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {project.siteManagerClient}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        Gewerk
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="div">
                                        {project.craft}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Stack spacing={2}>
                                <Typography variant="h6" component="div">
                                    Bautagebücher
                                </Typography>
                                <Box maxHeight={250} overflow="auto">
                                    <DiaryList project={project}/>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <Button 
                                        fullWidth
                                        variant="contained"
                                        startIcon={<DownloadIcon/>}
                                        onClick={handleDownload}
                                        disabled={project.diaries.length === 0}
                                    >
                                        Bautagebücher herunterladen
                                    </Button> 
                                </Grid>
                                
                                {
                                    project.archived ? (
                                        <Fragment>
                                            <Grid item xs={6} lg={4}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    startIcon={<UnarchiveIcon/>} 
                                                    onClick={handleUnarchive}
                                                >
                                                    Reaktivieren
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} lg={4}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    startIcon={<DeleteIcon/>} 
                                                    color='error'
                                                    onClick={() => setIsDeleteDialogOpen(true)}
                                                >
                                                    Löschen
                                                </Button> 
                                            </Grid>                                                                             
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Grid item xs={6} lg={4}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    startIcon={<EditIcon/>} 
                                                    onClick={() => setIsEditDialogOpen(true)}
                                                >
                                                    Bearbeiten
                                                </Button> 
                                            </Grid>
                                            <Grid item xs={6} lg={4}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    startIcon={<ArchiveIcon/>} 
                                                    onClick={handleArchive}
                                                >
                                                    Archivieren
                                                </Button> 
                                            </Grid>                                             
                                        </Fragment>
                                    ) 
                                }  
                            </Grid>         
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <ProjectsListEditDialog
                open={isEditDialogOpen}
                handleClose={() => setIsEditDialogOpen(false)}
                project={project}
            />
            <ConfirmDeclineDialog
                open={isDeleteDialogOpen}
                dialogTitle="Projekt löschen"
                dialogText="Sind Sie sich sicher, dass Sie das Projekt löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden."
                confirmText="Löschen"
                declineText="Abbrechen"
                handleClose={() => setIsDeleteDialogOpen(false)}
                handleConfirmActiom={handleDelete}
                handleDeclineAction={() => setIsDeleteDialogOpen(false)}
            />
        </Fragment>

    );
}

export default ProjectsListItem;
