import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { uploadFile } from "../../utils/firebase/firebase-storage.utils";
import { addEntry, FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";
import { generateUID } from "../../utils/uid";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";

import FormikImageSelection from "../formik-image/formik-image-selection.component";
import FormikButton from "../formik-button/formik-button.component";
import LoadingIndicator from "../loading/loading-indicator.component";

const INITIAL_STATE = {
    signatureImageFile: []
}

const FORM_VALIDATION = Yup.object().shape({
    signatureImageFile: Yup.array().min(1).max(1).required(),
});

const UserEditSignatureDialog = ({ open, handleClose, user }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            setIsLoading(true);

            const { signatureImageFile } = values;
        
            const signatureUrl = await uploadFile(
                `/users/${user.email}/${generateUID()}.jpg`, 
                signatureImageFile[0].blob
            );

            addEntry(
                FIREBASE_COLLECTION_NAMES.USERS,
                user.email,
                {
                    signatureUrl,
                }                    
            );

            handleClose();
            resetForm();
        } catch (error) {
            enqueueSnackbar("Das Ändern der Unterschrift ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("user signature update failed: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            <Formik
                initialValues={INITIAL_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
            >
                <Form> 
                    <Dialog 
                        open={open}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="xs"
                    >
                    <DialogTitle>Unterschrift ändern</DialogTitle>
                        <DialogContent>
                            <Stack 
                                spacing={2} 
                                marginY={2} 
                                alignItems="center"
                            >
                                <FormikImageSelection 
                                    name="signatureImageFile"
                                    multiple={false} 
                                    displayHeight={128}
                                    maxImageSize={800}
                                    uploadButtonText={"Unterschrift hochladen"}
                                />
                                <Typography variant="caption">
                                    Das empfohlene Seitenverhältnis ist 3:1 (bspw. 600 x 200 Pixel)
                                </Typography>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Abbrechen</Button>
                            <FormikButton>Speichern</FormikButton>
                        </DialogActions>
                    </Dialog>
                </Form>
            </Formik>
            <LoadingIndicator
                open={isLoading}
                text="Unterschrift wird geändert"
            />
        </Fragment>
    );
}

export default UserEditSignatureDialog;