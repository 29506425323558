import JSZip from "jszip";
import { saveAs } from "save-as";

import { downloadFile, getFileMetadata } from "./firebase/firebase-storage.utils";

export const zipFilesFromUrl = async (folderName, urls) => {
    const zip = new JSZip();
    for(let i = 0; i < urls.length; i++) {
        const file = await downloadFile(urls[i]);
        const { name } = await getFileMetadata(urls[i]);
        zip.file(name, file)
    }    

    const content = await zip.generateAsync({ type: "blob" })
    saveAs(content, folderName);
}   

