import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const StepperHeader = ({ steps, activeStep }) => {
  return (
    <Grid container marginY={4} sx={{ display: { xs: 'none', sm: 'block' }}}>
        <Grid item xs={12}>
            <Stepper activeStep={activeStep}>
                {
                    steps.map((label, idx) => {
                        return (
                            <Step key={idx} completed={activeStep > idx}>
                                <StepLabel>
                                    {label}
                                </StepLabel>
                            </Step>
                        )
                    })    
                }
            </Stepper>
        </Grid>
    </Grid>
  )
}

export default StepperHeader;