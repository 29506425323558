import { useEffect, useState } from "react";

import Image from "mui-image";

const FileImage = ({ image, displayHeight }) => {
    const [imageSource, setImageSource] = useState(null);

    useEffect(() => {
        // URL
        if(typeof image === 'string' || image instanceof String) {
            setImageSource(image);
        }
        // Base64
        else { 
            setImageSource(image.base64); 
        }        
    }, [image]);

    return (
        <Image 
            height={displayHeight} 
            src={imageSource}
        />
    );
}

export default FileImage;