import { USER_ACTION_TYPES, USER_AUTH_STATES } from "./user.types";

const INITIAL_STATE = {
    authStatus: USER_AUTH_STATES.UNKNOWN,
    authUser: null,
    enhancedUser: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case USER_ACTION_TYPES.SET_SIGN_IN_STARTED:
            return { 
                ...state, 
                authStatus: USER_AUTH_STATES.SIGNED_IN_STARTED,
                authUser: payload,
                enhancedUser: null
            };

        case USER_ACTION_TYPES.SET_SIGN_IN_FINISHED:
            return {
                ...state,
                authStatus: USER_AUTH_STATES.SIGNED_IN_FINISHED,
                enhancedUser: payload,
            }

        case USER_ACTION_TYPES.SET_SIGNED_OUT:
            return {
                ...state,
                authStatus: USER_AUTH_STATES.SIGNED_OUT,
                authUser: null,
                enhancedUser: null
            }

        default:
            return state;
    }
};