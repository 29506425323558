import { httpsCallable } from "firebase/functions";

import { functions } from "./firebase.utils";

export const addUser = async (email, password) => {
    try {
        const cloudFunction = httpsCallable(functions, 'addUser');
        const response = await cloudFunction({ email, password });
        return response.data;
    } catch (error) {
        console.log(`error occured during user creation: `, error);
    }
}

export const deleteUser = async (email) => {
    try {
        const cloudFunction = httpsCallable(functions, 'deleteUser');
        const response = await cloudFunction({ email });
        return response.data;
    } catch (error) {
        console.log(`error occured during user deletion: `, error);
    }   
}