import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import { 
    setDiaryWeatherData, 
    nextDiaryPage, 
    previousDiaryPage,
} from '../../store/diary/diary.action';

import { 
    selectDiaryWeatherData, 
    selectDiaryWeatherDataRequested 
} from '../../store/diary/diary.selector';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import FormikTextfield from '../formik-textfield/formik-textfield.component';
import StepperHeader from '../stepper/stepper-header.component';
import StepperFooter from "../stepper/stepper-footer.component";
import FormikSwitch from '../formik-switch/formik-switch.component';

const FORM_VALIDATION = Yup.object().shape({
    sun: Yup.bool(),
    rain: Yup.bool(),
    frost: Yup.bool(),
    wind: Yup.bool(),
    snow: Yup.bool(),
    temperature: Yup.number("Keine Zahl").required("Erforderlich"),
    otherInformation: Yup.string(),
}); 

const DiaryNewPage2 = ({ steps, activeStep }) => {
    const dispatch = useDispatch();
    const weatherDataRequested = useSelector(selectDiaryWeatherDataRequested);
    const weatherData = useSelector(selectDiaryWeatherData);

    const handleBack = () => { 
        dispatch(previousDiaryPage());
    }
    
    const handleSubmit = (values) => {
        dispatch(setDiaryWeatherData(values));
        dispatch(nextDiaryPage());
    }    

    return (
        weatherDataRequested ? (
            <Formik
                initialValues={{...weatherData}}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
            >
                <Form>
                    <StepperHeader
                        activeStep={activeStep}
                        steps={steps}
                    />                        
                    <Grid container marginY={2} spacing={2}>
                        {/* Sun */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <Typography>
                                Sonne
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <FormikSwitch name="sun" />
                        </Grid>
                        {/* Rain */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <Typography>
                                Regen
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            {/* 0.2 mm equals drizzle */}
                            <FormikSwitch name="rain" />
                        </Grid>
                        {/* Frost */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <Typography>
                                Frost
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <FormikSwitch name="frost" />
                        </Grid>
                        {/* Wind */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            {/* starting 20 km/h it's not anymore light wind */}
                            <Typography>
                                Wind
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <FormikSwitch name="wind" />
                        </Grid>
                        {/* Snow */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <Typography>
                                Schnee
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <FormikSwitch name="snow" />
                        </Grid>
                        {/* Temperature */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <Typography>
                                Temperatur
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <FormikTextfield 
                                name="temperature"
                                fullWidth 
                                size="small" 
                                variant="outlined"
                            />
                        </Grid>
                        {/* Others */}
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <Typography 
                                    
                                
                            >
                                Sonstiges
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} minHeight={62}>
                            <FormikTextfield 
                                name="otherInformation"
                                fullWidth 
                                size="small" 
                                variant="outlined"
                                multiline
                            />
                        </Grid>
                    </Grid>
                    <StepperFooter 
                        steps={steps}
                        activeStep={activeStep} 
                        handleBack={handleBack} 
                    /> 
                </Form>
            </Formik>    
        ) : null   
    );
}

export default DiaryNewPage2;