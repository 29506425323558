import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { saveAs } from "save-as";

import { addEntry, FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";
import { deleteFile, downloadFile, getFileMetadata } from "../../utils/firebase/firebase-storage.utils";
import { getDifferenceInHours } from "../../utils/date";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";


import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDeclineDialog from "../confirm-decline-dialog/confirm-decline-dialog.component";

const DiaryListItem = ( { project, diary }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [downloading, setDownloading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false); 

    const handleDownload = async () => {
        if(downloading) {
            enqueueSnackbar("Der Download startet in wenigen Sekunden.", { variant: 'info' });
            return;
        }

        try {
            setDownloading(true);
            const content = await downloadFile(diary.pdfUrl);
            const { name } = await getFileMetadata(diary.pdfUrl);
            saveAs(content, name);
        } catch (error) {
            enqueueSnackbar("Der Download ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            enqueueSnackbar("Stellen Sie sicher, dass kein Adblocker auf dieser Seite aktiv ist.", { variant: 'info '});
            console.log("download of diary failed: ", error);
        } finally {
            setDownloading(false);
        }
    }

    const handleDelete = async () => {
        try {
            // Storage
            deleteFile(project.pdfUrl);

            // Firestore
            const newDiaries = project.diaries.filter(projectDiary => projectDiary.createdAt !== diary.createdAt);
            addEntry(
                FIREBASE_COLLECTION_NAMES.PROJECTS,
                project.projectNumber,
                {
                    ...project,
                    diaries: newDiaries,
                }
            );            

            setDialogOpen(false);
        } catch (error) {
            enqueueSnackbar("Das Löschen des Bautagebuches ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("deletion of diary failed: ", error);
        }
    }

    return (
        <Fragment>
            <ListItem>
                <Grid container maxWidth={400} margin="auto">
                    <Grid item xs={2} sm={3}>
                        <Typography textAlign="start" lineHeight="40px">
                            {diary.diaryNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                        <Typography textAlign="center" lineHeight="40px">
                            {diary.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} textAlign="end">
                        <IconButton onClick={handleDownload}>
                            <FileDownloadIcon/>
                        </IconButton>
                        {
                            getDifferenceInHours(diary.createdAt.toDate(), new Date()) < 4 ? (
                                <IconButton onClick={() => setDialogOpen(true)}>
                                    <DeleteIcon color="error"/>
                                </IconButton>  
                            ) : null
                        }                                    
                    </Grid>
                </Grid>
            </ListItem>   
            <ConfirmDeclineDialog
                open={dialogOpen}
                dialogTitle="Bautagebuch löschen"
                dialogText="Sind Sie sich sicher, dass Sie das Bautagebuch löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden."
                confirmText="Löschen"
                declineText="Abbrechen"
                handleClose={() => setDialogOpen(false)}
                handleConfirmActiom={handleDelete}
                handleDeclineAction={() => setDialogOpen(false)}
            />
        </Fragment>     
    )
}

export default DiaryListItem;