import { DIARY_OVERVIEW_ACTION_TYPES } from "./diary-overview.types";

const INITIAL_STATE = {
    projectNumber: null
}

export const diaryOverviewReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case DIARY_OVERVIEW_ACTION_TYPES.SET_PROJECT_NUMBER:
            return { ...state, projectNumber: payload};

        default:
            return state;
    }
}