import { createSelector } from "reselect";

import { selectActiveProjects } from "../projects/projects.selector";

export const selectUser = (state) => state.user;


export const selectAuthStatus = createSelector(
    [selectUser],
    user => user.authStatus
)

export const selectAuthUser = createSelector(
    [selectUser],
    user => user.authUser
)

export const selectEnhancedUser = createSelector(
    [selectUser],
    user => user.enhancedUser
)

const selectCurrentUserProjectNumbers = createSelector(
    [selectEnhancedUser],
    (currentUser) => currentUser.projectNumbers
)

export const selectCurrentUserProjects = createSelector(
    [selectCurrentUserProjectNumbers, selectActiveProjects],
    (projectNumbers, activeProjects) => activeProjects.reduce((acc, curr) => {
        if(projectNumbers.includes(curr.projectNumber)) {
            acc.push(curr);
        }
        return acc;
    }, [])
)