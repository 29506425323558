import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const columnWidth = 200;

const createPDF= async (
    projectData, 
    weather,
    employees,
    results,
    signatureDate,
    signatureLocation,
    signatureUrl
) => {
    const imagesDefinition = [];
    let imagesAdded = 0;
    for(let i = 0; i < results.images.length; i++) {
        imagesDefinition.push({
            image: `${results.images[i].base64}`,
            fit: [555.35, 360],
            alignment: 'center'
        });        

        if(imagesAdded === 0) {
            imagesDefinition[imagesDefinition.length - 1].pageBreak = 'before';
        } 

        if(imagesAdded === results.images.length - 1) {
            imagesDefinition[imagesDefinition.length - 1].pageBreak = 'after';
        } else {
            imagesDefinition.push('\n');
        }

        imagesAdded++;
    }   

    if(imagesDefinition.length === 0) {
        imagesDefinition.push({
            text: '',
            pageBreak: 'after'
        });
    }

    const docDefinition = {
        pageMargins: [ 20, 70, 20, 20 ],
        header: {
            columns: [
                {
                    text: 'BAUTAGEBUCH',
                    fontSize: 18,
                    bold: true,
                },
                {
                    image: 'logoImage',
                    width: 160,
                    alignment: 'right'
                },
            ],
            margin: [20, 10, 20, 0]
        },
        footer: (currentPage, pageCount) => { 
            return {
                text: `${currentPage} / ${pageCount}`,
                alignment: 'center',
            }
        },
        content: [
            // Projektdaten
            {
                text: 'Projektdaten',
                style: 'header'
            },
            `\n`,
            {
                columns: [
                    {
                        text: `Datum`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.date}`,
                        style: 'text'
                    }
                ]
            },     
            {
                columns: [
                    {
                        text: `KW`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.week}`,
                        style: 'text'
                    }
                ]
            },               
            {
                columns: [
                    {
                        text: `Bau-Tagesbericht-Nr.`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.diaryNumber}`,
                        style: 'text'
                    }
                ]
            },    
            {
                columns: [
                    {
                        text: `Bauleiter Wegener`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.siteManager}`,
                        style: 'text'
                    }
                ]
            }, 
            {
                columns: [
                    {
                        text: `Bauleiter Auftraggeber`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.siteManagerClient}`,
                        style: 'text'
                    }
                ]
            }, 
            {
                columns: [
                    {
                        text: `Leistungsempfänger`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.beneficiary}`,
                        style: 'text'
                    }
                ]
            }, 
            {
                columns: [
                    {
                        text: `Projektnummer Wegener`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.projectNumber}`,
                        style: 'text'
                    }
                ]
            },
            {
                columns: [
                    {
                        text: `Baustelle, Anschrift`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.siteStreetName} ${projectData.siteHouseNumber}, ${projectData.siteZipCode} ${projectData.siteCityName}, ${projectData.siteCountry}`,
                        style: 'text'
                    }
                ]
            },
            {
                columns: [
                    {
                        text: `Projektnummer Auftraggeber`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.projectNumberClient}`,
                        style: 'text'
                    }
                ]
            },
            {
                columns: [
                    {
                        text: `Gewerk`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${projectData.craft}`,
                        style: 'text'
                    }
                ]
            },
            `\n\n`,
            // Wetter
            {
                text: 'Wetter',
                style: 'header'
            },
            `\n`,
            {
                columns: [
                    {
                        text: `Sonne`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${weather.sun ? 'Ja' : 'Nein'}`,
                        style: 'text'
                    }
                ]
            }, 
            {
                columns: [
                    {
                        text: `Regen`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${weather.rain ? 'Ja' : 'Nein'}`,
                        style: 'text'
                    }
                ]
            }, 
            {
                columns: [
                    {
                        text: `Frost`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text:  `${weather.frost ? 'Ja' : 'Nein'}`,
                        style: 'text'
                    }
                ]
            }, 
            {
                columns: [
                    {
                        text: `Wind`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${weather.wind ? 'Ja' : 'Nein'}`,
                        style: 'text'
                    }
                ]
            },
            {
                columns: [
                    {
                        text: `Schnee`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${weather.snow ? 'Ja' : 'Nein'}`,
                        style: 'text'
                    }
                ]
            },
            {
                columns: [
                    {
                        text: `Temperatur`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${weather.temperature} °C`,
                        style: 'text'
                    }
                ]
            },
            {
                columns: [
                    {
                        text: `Sonstiges`,
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${weather.otherInformation.length > 0 ? weather.otherInformation : "Keine Angabe"}`,
                        style: 'text'
                    }
                ]
            },
            `\n\n`,
            // Monteure
            {
                text: 'Monteure auf der Baustelle',
                style: 'header'
            },
            `\n`,
            {
                columns: [                    
                    {
                        text: `Gesellen`,                       
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${employees.craftsmen.length > 0 ? employees.craftsmen.join(', ') : 'Keine Angabe'}`,
                        style: 'text'
                    },
                ]
            },
            {
                columns: [                    
                    {
                        text: `Auszubildene`,                       
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${employees.apprentices.length > 0 ? employees.apprentices.join(', ') : 'Keine Angabe'}`,
                        style: 'text'
                    },
                ]
            },
            {
                columns: [                    
                    {
                        text: `Fremdfirmen`,                       
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${employees.thirdParties.length > 0 ? employees.thirdParties.join(', ') : 'Keine Angabe'}`,
                        style: 'text'
                    },
                ]
            },
            `\n\n`,
            // Leistungsergebnisse/-änderungen, Bemerkungen, Vorkommnisse
            {
                text: 'Leistungsergebnisse/-änderungen, Bemerkungen, Vorkommnisse',
                style: 'header'
            },
            `\n`,
            {
                columns: [                    
                    {
                        text: `Leistungsergebnisse/-änderungen`,                       
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${results.summary.length > 0 ? results.summary : "Keine Angabe"}`,
                        style: 'text'
                    },
                ]
            },           
            {
                columns: [                    
                    {
                        text: [
                            { 
                                text: 'Bemerkungen ',
                                style: 'subheader'
                            },
                            {
                                text: '(Behinderungen / Erschwernisse / ...)',
                                style: 'text'
                            }
                        ],                       
                        width: columnWidth,
                    },
                    {
                        text: `${results.comments.length > 0 ? results.comments : 'Keine Angabe'}`,
                        style: 'text'
                    },
                ]
            },             
            {
                columns: [                    
                    {
                        text: [
                            { 
                                text: 'Besondere Vorkommnisse ',
                                style: 'subheader'
                            },
                            {
                                text: '(Begehung / Abnahme / ...)',
                                style: 'text'
                            }
                        ],                       
                        width: columnWidth,
                    },
                    {
                        text: `${results.specialEvents.length > 0 ? results.specialEvents : "Keine Angabe"}`,
                        style: 'text'
                    },
                ],
            }, 
            ...imagesDefinition,
            // Unterschriften
            {
                text: 'Unterschriften',
                style: 'header'
            },
            `\n`,
            {
                columns: [
                    {
                        text: 'Datum / Ort',
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        text: `${signatureLocation}, ${signatureDate}`,
                        style: 'text'
                    }
                ],             
            },
            {
                columns: [
                    {
                        text: 'Unterschrift Bauleiter',
                        width: columnWidth,
                        style: 'subheader'
                    },
                    {
                        image: 'signatureImage',
                        fit: [192, 64]
                    }
                ]                
            },
            `\n\n\n`,
            {
                columns: [
                    {
                        text: 'Datum / Ort',
                        width: columnWidth,
                        style: 'signatureHeader'
                    },
                    {
                        text: '___________________________________________',
                        style: 'signatureText'
                    }
                ]                
            },
            {
                columns: [
                    {
                        text: 'Unterschrift Auftraggeber',
                        width: columnWidth,
                        style: 'signatureHeader'
                    },
                    {
                        text: '___________________________________________',
                        style: 'signatureText'
                    }
                ]                
            },
        ],
        styles: {
            header: {
                fontSize: 15,
                bold: true,
            },
            subheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 4],
            },
            text: {
                fontSize: 12,
                alignment: "justify",
                margin: [0, 0, 0, 4],
            },
            signatureHeader: {
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 24],
            },
            signatureText: {
                fontSize: 12,
                alignment: "justify",
                margin: [0, 0, 0, 24],
            }
        },
        images: {
            signatureImage: `${signatureUrl}`,
            logoImage: `https://firebasestorage.googleapis.com/v0/b/wegener-bautagebuch.appspot.com/o/Logo.JPG?alt=media&token=81628c5e-4d37-4f43-8558-27844ea2724f`,
        }
    }

    return pdfMake.createPdf(docDefinition);
}

export const createPdfAsBlob = (
    projectData, 
    weather,
    employees,
    results,
    signatureDate,
    signatureLocation,
    signatureUrl
) => {
    return new Promise(async (resolve, _) => {
        const pdfDocGenerator = await createPDF(
            projectData, 
            weather,
            employees,
            results,
            signatureDate,
            signatureLocation,
            signatureUrl
        );
        pdfDocGenerator.getBlob((blob) => {
            resolve(blob);
        })
    });
}