import { Fragment } from "react";
import { useField, useFormikContext } from "formik";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Image from "mui-image";

const FormikSignature = ({ name, signatureSrc }) => {
    const [field] = useField(name);
    const { values, setValues } = useFormikContext();

    const handleClick = () => {
        setValues({ ...values, [name]: true });
    }

    return (
        <Fragment>
            {
                values[name] !== null && values[name] ? (
                    <Box height={{ xs: "96px", sm: "192px" }} width={{ xs: "192px", sm: "384px" }}>
                        <Image 
                            name={field.name} 
                            src={signatureSrc}
                        />  
                    </Box>                                     
                ) : (
                    <Button name={field.name} variant="contained" onClick={handleClick}>
                        Unterschreiben
                    </Button>
                )
            }
        </Fragment>        
    );
}

export default FormikSignature;