import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { selectEnhancedUser } from '../../store/user/user.selector';

import { sendResetPasswordEmail } from '../../utils/firebase/firebase-auth.utils';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';

import Image from 'mui-image';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditIcon from '@mui/icons-material/Edit';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import UserAccordionProjectsList from './user-accordion-projects-list.component';
import UserEditDialog from './user-edit-dialog.component';
import UserEditSignatureDialog from './user-edit-signature-dialog.component';
import ConfirmDeclineDialog from '../confirm-decline-dialog/confirm-decline-dialog.component';
import { deleteUser } from '../../utils/firebase/firebase-functions.utils';
import { deleteEntry, FIREBASE_COLLECTION_NAMES } from '../../utils/firebase/firebase-firestore.utils';


const User = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); 
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isEditSignatureDialogOpen, setIsEditSignatureDialogOpen] = useState(false);

    const currentUser = useSelector(selectEnhancedUser);
    const { displayName, email, admin, signatureUrl } = user;

    const resetPassword = async () => {
        enqueueSnackbar("Email ist versendet", { variant: "success" });
        await sendResetPasswordEmail(email);
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false);

            // Auth
            await deleteUser(email);

            // Firestore
            deleteEntry(FIREBASE_COLLECTION_NAMES.USERS, email);

        } catch (error) {
            enqueueSnackbar("Das Löschen des Benutzers ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("user deletion failed: ", error);
        }
    };

  return (
    <Fragment>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography>{displayName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" component="div">
                                    Email-Adresse
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" component="div">
                                    {email}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" component="div">
                                    Administrator
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Switch name="admin" checked={admin} disabled />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" component="div">
                                    Unterschrift
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Image 
                                    src={signatureUrl} 
                                    alt="Unterschrift"
                                    fullWidth
                                    fit="cover"
                                />
                            </Grid>
                        </Grid>                         
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>                        
                            <Typography variant="h6" component="div">
                                Zugeteilte Projekte
                            </Typography>
                            <Box maxHeight={250} overflow="auto">
                                <UserAccordionProjectsList user={user}/>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button 
                                    fullWidth
                                    variant="contained" 
                                    startIcon={<EditIcon/>}
                                    onClick={() => setIsEditDialogOpen(true)}
                                >
                                    Bearbeiten
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button 
                                    fullWidth
                                    variant="contained" 
                                    startIcon={<HistoryEduIcon/>}
                                    onClick={() => setIsEditSignatureDialogOpen(true)}
                                >
                                    Unterschrift ändern
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button 
                                    fullWidth
                                    variant="contained" 
                                    startIcon={<LockResetIcon/>}
                                    onClick={resetPassword}
                                >
                                    Passwort zurücksetzen
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button 
                                    fullWidth
                                    variant="contained" 
                                    color="error"
                                    startIcon={<DeleteIcon/>} 
                                    onClick={() => setIsDeleteDialogOpen(true)}
                                    disabled={currentUser.email === email}
                                >
                                    Löschen
                                </Button>
                            </Grid>
                        </Grid>                        
                    </Grid>                    
                </Grid>
            </AccordionDetails>
        </Accordion>
        <UserEditDialog
            open={isEditDialogOpen}
            handleClose={() => setIsEditDialogOpen(false)}
            user={user}
        />
        <UserEditSignatureDialog
            open={isEditSignatureDialogOpen}
            handleClose={() => setIsEditSignatureDialogOpen(false)}
            user={user}
        />
        <ConfirmDeclineDialog
            open={isDeleteDialogOpen}
            dialogTitle="Benutzer löschen"
            dialogText="Sind Sie sich sicher, dass Sie diesen Benutzer löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden."
            confirmText="Löschen"
            declineText="Abbrechen"
            handleClose={() => setIsDeleteDialogOpen(false)}
            handleConfirmActiom={handleDelete}
            handleDeclineAction={() => setIsDeleteDialogOpen(false)}
        />
    </Fragment>
  );
}

export default User;
