import { DIARY_ACTION_TYPES } from "./diary.types";
import { getCurrentDate, getWeekNumber } from "../../utils/date";
import { createAction } from '../../utils/reducer/reducer';

export const resetDiary = () => {
    return createAction(DIARY_ACTION_TYPES.RESET);
}

export const setDiaryLoading = (loading) => {
    return createAction(DIARY_ACTION_TYPES.SET_LOADING, loading);
}

export const previousDiaryPage = () => {
    return createAction(DIARY_ACTION_TYPES.PREVIOUS_STEP);
}

export const nextDiaryPage = () => {
    return createAction(DIARY_ACTION_TYPES.NEXT_STEP);
}

export const setDiaryProject = (project) => {
    const projectData = { 
        date: getCurrentDate(),
        week: getWeekNumber(new Date()),
        beneficiary: project.beneficiary, 
        craft: project.craft, 
        projectNumber: project.projectNumber, 
        projectNumberClient: project.projectNumberClient, 
        siteStreetName: project.siteStreetName,
        siteHouseNumber: project.siteHouseNumber,
        siteZipCode: project.siteZipCode,
        siteCityName: project.siteCityName,
        siteCountry: project.siteCountry,
        siteManager: project.siteManager,
        siteManagerClient: project.siteManagerClient
    };
    const siteLatLong = {
        siteLat: project.siteLat,
        siteLong: project.siteLong,
    }
    return createAction(DIARY_ACTION_TYPES.SET_PROJECT, { projectData, siteLatLong });
}

export const setDiaryProjectData = (projectData) => {
    return createAction(DIARY_ACTION_TYPES.SET_PROJECT_DATA, projectData);
}

export const resetDiaryWeatherData = () => {
    const resetWeatherData = {
        sun: false,
        rain: false,
        frost: false,
        wind: false,
        snow: false,
        temperature: 20,
        otherInformation: '',
    };
    return createAction(DIARY_ACTION_TYPES.SET_WEATHER_DATA, resetWeatherData)
}

export const presetDiaryWeatherData = (weatherData) => {
    const enhancedWeatherData = {
        sun: weatherData.cloud <= 20.0,
        rain: weatherData.rain > 0.2,
        frost: weatherData.temp <= 0.0,
        wind: weatherData.wind >= 15.0,
        snow: weatherData.temp < -3.0 && weatherData.rain > 0.2,
        temperature: weatherData.temp,
        otherInformation: '',
    };
    return createAction(DIARY_ACTION_TYPES.SET_WEATHER_DATA, enhancedWeatherData)
}

export const setDiaryWeatherData = (weatherData) => {
    return createAction(DIARY_ACTION_TYPES.SET_WEATHER_DATA, weatherData)
}

export const setDiaryEmployees = (employees) => {
    return createAction(DIARY_ACTION_TYPES.SET_EMPLOYEES, employees);
}

export const setDiaryResults = (results) => {
    return createAction(DIARY_ACTION_TYPES.SET_RESULTS, results);
}