import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { onAuthStateChangedListener } from './utils/firebase/firebase-auth.utils';
import { getEntry, getCollection, onCollectionChangedListener } from './utils/firebase/firebase-firestore.utils';

import { setUsers } from './store/users/users.action';
import { setProjects } from './store/projects/projects.action';
import { setCraftsmen } from './store/craftsmen/craftsmen.action';
import { setApprentices } from './store/apprentices/apprentices.action';
import { setThirdParties } from './store/third-parties/third-parties.action';
import { setSignedOut, setSignInStarted, setSignInFinished } from './store/user/user.action';

import { selectAuthStatus, selectAuthUser } from './store/user/user.selector';

import { USER_AUTH_STATES } from './store/user/user.types';

import AuthRouteUser from './utils/auth-routes/auth-route-user.component';
import AuthRouteAdmin from './utils/auth-routes/auth-route-admin.component';
import Navigation from './pages/navigation/navigation.component';
import Authentication from './pages/authentication/authentication.component';
import DiaryOverview from './pages/diary-overview/diary-overview.component';
import DiaryNew from './pages/diary-new/diary-new.component';
import Projects from './pages/projects/projects.component';
import Employees from './pages/employees/employees.component';
import Users from './pages/users/users.component';
import LoadingSpinner from './components/loading/loading-spinner.component';

function App() {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);

  // Auth User
  useEffect(() => {
    const unsub = onAuthStateChangedListener(user => {
      if(user) 
        dispatch(setSignInStarted(user));
      else
        dispatch(setSignedOut());
    });
    return () => unsub();
  }, []);

  // Enhanced User
  useEffect(() => {
    console.log(authStatus);
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getCurrentUser = async () => {
        const currentUser = await getEntry("users", authUser.email);
        dispatch(setSignInFinished(currentUser));
      }
      getCurrentUser();
      const unsub = onCollectionChangedListener('users', () => getCurrentUser());
      return () => unsub();
    }                
    
  }, [authUser, authStatus]);

  // Users
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getUsers = async () => {
        const users = await getCollection('users');
        dispatch(setUsers(users));
      };
      getUsers();
      const unsub = onCollectionChangedListener('users', () => getUsers());
      return () => unsub();
    }
  }, [authStatus]);

  // Projects
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getProjects = async () => {
        const projects = await getCollection("projects");
        dispatch(setProjects(projects));
      };
      getProjects();
      const unsub = onCollectionChangedListener("projects", () => getProjects());
      return () => unsub();
    }
  }, [authStatus]);

  // Craftsmen
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getCraftsmen = async () => {
          const craftsmen = await getCollection("craftsmen");
          dispatch(setCraftsmen(craftsmen));
      };
      getCraftsmen();
      const unsub = onCollectionChangedListener("craftsmen", () => getCraftsmen());
      return () => unsub();
    }
  }, [authStatus]);

  // Apprentices
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getApprentices = async () => {
          const apprentices = await getCollection("apprentices");
          dispatch(setApprentices(apprentices));
      };
      getApprentices();
      const unsub = onCollectionChangedListener("apprentices", () => getApprentices());
      return () => unsub();
    }
  }, [authStatus]);

  // Third Parties
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED) {
      const getThirdParties = async () => {
          const thirdParties = await getCollection("thirdParties");
          dispatch(setThirdParties(thirdParties));
      };
      getThirdParties();
      const unsub = onCollectionChangedListener("thirdParties", () => getThirdParties());
      return () => unsub();
    }
  }, [authStatus]);

  console.log(authStatus);

  return (
    authStatus !== USER_AUTH_STATES.UNKNOWN ? (
      <Routes>
        <Route path="/" element={<Navigation/>}>
          <Route index element={<Authentication/>}/>
          <Route path="user/" element={<AuthRouteUser/>}>
            <Route index path="diary-overview" element={<DiaryOverview/>}/>          
            <Route path="diary-new" element={<DiaryNew/>}/>
          </Route>      
          <Route path="admin/" element={<AuthRouteAdmin/>}>
            <Route index path="projects" element={<Projects/>}/>
            <Route path="employees" element={<Employees/>}/>
            <Route path="users" element={<Users/>}/>
          </Route>      
        </Route>
      </Routes>
    ) : (
      <LoadingSpinner/>
    )  
  );
}

export default App;
