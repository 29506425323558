import { useField } from 'formik';

import Switch from '@mui/material/Switch';

const FormikSwitch = ({ name, ...otherProps }) => {
    const [field] = useField(name);

    const configSwitch = {
        ...field,
        ...otherProps,
        checked: field.value,
        inputProps: { 'aria-label': 'controlled' },
    };

    return <Switch {...configSwitch}/>;
}

export default FormikSwitch;