import { combineReducers } from 'redux';

import { userReducer } from './user/user.reducer';
import { usersReducer } from './users/users.reducer';
import { projectsReducer } from './projects/projects.reducer';
import { craftsmenReducer } from './craftsmen/craftsmen.reducer';
import { apprenticesReducer } from './apprentices/apprentices.reducer';
import { thirdPartiesReducer } from './third-parties/third-parties.reducer';
import { diaryReducer } from './diary/diary.reducer';
import { diaryOverviewReducer } from './diary-overview/diary-overview.reducer';

export const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  projects: projectsReducer,
  craftsmen: craftsmenReducer,
  apprentices: apprenticesReducer,
  thirdParties: thirdPartiesReducer,
  diary: diaryReducer,
  diaryOverview: diaryOverviewReducer,
});