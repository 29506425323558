import { useState } from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ProjectsListItem from './projects-list-item.component';

const ProjectsList = ({ title, projects }) => {
    const [expanded, setExpanded] = useState(false);

    const handleOpenAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" component="h6" textAlign="center">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {
                        projects.map((project, idx) => 
                            <ProjectsListItem 
                                expanded={expanded}
                                openAccordion={handleOpenAccordion}
                                key={idx} 
                                project={project} 
                            />
                        )
                    }
                </List>
            </Grid>
        </Grid>               
    );
}

export default ProjectsList;