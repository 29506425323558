import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <Box 
      padding={2}
      sx={{ backgroundColor: "primary.main"}}
    >
      <Grid 
      container 
      spacing={2}
      
      >
        <Grid item xs={12} md={4} textAlign="center">
          <Typography 
            variant="subtitle2" 
            sx={{ color: "white" }}
          >
            Karl-Heinz Wegener GmbH & Co KG Elektroanlagen 
          </Typography>
          <Typography 
            variant="subtitle2" 
            sx={{ color: "white" }}
          >
            Daimlerstr. 31
          </Typography>
          <Typography 
            variant="subtitle2" 
            sx={{ color: "white" }}
          >
            48683 Ahaus
          </Typography>     
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <Typography           
            variant="subtitle2" 
            sx={{ color: "white", cursor: "pointer" }}
            onClick={() => window.open("mailto:info@wegener-elektro.de")}
          >
            Email: info@wegener-elektro.de 
          </Typography>
          <Typography 
            variant="subtitle2" 
            sx={{ color: "white", cursor: "pointer" }}
            onClick={() => window.open("tel:+492561695600")}
          >
            Tel.: 02561 / 69560-0
          </Typography>      
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <Typography           
            variant="subtitle2" 
            sx={{ color: "white", cursor: "pointer" }}
            onClick={() => window.location.href = "https://www.wegener-elektro.de/index.php/impressum/"}
          >
            Impressum
          </Typography>
          <Typography           
            variant="subtitle2" 
            sx={{ color: "white", cursor: "pointer" }}
            onClick={() => window.location.href = "https://www.wegener-elektro.de/index.php/datenschutzerklaerung/"}
          >
            Datenschutz
          </Typography>       
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;