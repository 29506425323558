import { PROJECTS_ACTION_TYPES } from "./projects.types";

const INITIAL_STATE = {
    projects: [],
}

export const projectsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case PROJECTS_ACTION_TYPES.SET_PROJECTS:
            return {...state, projects: payload };
        
        default:
            return state;
    }
}