import { Fragment } from "react";

import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import DiaryListItem from "./diary-list-item.component";

const DiaryList = ({ 
    project 
}) => {
    if(!project) { return null; }

    return (
        <Fragment>
            {
                project.diaries.length > 0 ? (
                    <List>
                        {
                            project.diaries.map((diary, idx) => {
                                return <DiaryListItem key={idx} project={project} diary={diary} />
                            })
                        }
                    </List>
                ) : (
                    <Typography textAlign="center">
                        Es liegen keine Bautagebücher vor
                    </Typography>
                )
            }
        </Fragment>        
    );
}

export default DiaryList;