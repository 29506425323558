import { useState } from "react";
import { useFormikContext } from "formik";
import _ from "lodash";

import { blobToBase64, resizeImage } from "../../utils/image";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { CircularProgress } from "@mui/material";

const FormikFileButton = ({ 
    name, 
    displayHeight, 
    multiple, 
    buttonText,
    onStartSelection,
    onEndSelection
 }) => {
    const { enqueueSnackbar } = useFormikContext();
    const { values, setValues } = useFormikContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async (event) => {
        try {
            setIsLoading(true);
            onStartSelection();

            let joinedValues = values[name];
            let newValues = Array.from(event.target.files).filter(image => image.type.startsWith("image/"));
            
            for(let i = 0; i < newValues.length; i++) {
                const resizedImage = await resizeImage(newValues[i], 1000);
                const base64Image = await blobToBase64(resizedImage);
                const newValue = [{ name: newValues[i].name, blob: resizedImage, base64: base64Image }];

                if(multiple) {
                    joinedValues = _.unionWith(joinedValues, newValue, (a, b) => a.name === b.name); 
                    setValues({ ...values, [name]: joinedValues });
                } else {
                    setValues({ ...values, [name]: newValue });
                }
            }  
        } catch(error) {
            enqueueSnackbar("Das Hinzufügen von Bildern ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("adding images failed: ", error);
        } finally {
            onEndSelection();
            setIsLoading(false);
        }        
    }

    return (
        <Box>
            {
                !isLoading ? (
                    <Box>
                        <Box 
                            height={displayHeight}
                            sx={{ backgroundColor: 'lightGrey', display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                            <AddPhotoAlternateIcon color="secondary" sx={{ fontSize: "96px" }}/>
                        </Box>
                        <label htmlFor="images">
                            <input
                                name={name}
                                values={values}
                                id="images"
                                style={{ display: 'none' }}
                                onChange={handleChange}
                                type="file"
                                accept="image/*"
                                multiple={multiple}
                            />             
                            <Button 
                                fullWidth
                                disableElevation
                                sx={{ borderRadius: 0 }}
                                color="secondary" 
                                variant="contained" 
                                component="span"
                            >
                                {buttonText}
                            </Button>            
                        </label>
                    </Box>
                    
                ) : (
                    <Box 
                        height={displayHeight + 36.5}
                        sx={{ backgroundColor: 'lightGrey', display: "flex", justifyContent: "center", alignItems: "center"}}
                    >
                        <CircularProgress disableShrink/>
                    </Box>                    
                )
            }
        </Box>
    );
}

export default FormikFileButton;