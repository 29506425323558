import { CRAFTSMEN_ACTION_TYPES } from "./craftsmen.types";

const INITIAL_STATE = {
    craftsmen: [],
}

export const craftsmenReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case CRAFTSMEN_ACTION_TYPES.SET_CRAFTSMEN:
            return { ...state, craftsmen : payload };

        default:
            return state;
    }
}