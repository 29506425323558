import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { selectActiveProjects } from '../../store/projects/projects.selector';

import { addEntry, FIREBASE_COLLECTION_NAMES } from '../../utils/firebase/firebase-firestore.utils';

import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export const UserAccordionProjectsList = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(user.projectNumbers);
  const activeProjects = useSelector(selectActiveProjects);

  const handleToggle = (value) => () => {
    try {
      const currentIndex = checked.indexOf(value);
      let newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);

      addEntry(
        FIREBASE_COLLECTION_NAMES.USERS, 
        user.email, 
        {
          ...user,
          projectNumbers: newChecked
        }
      );
    } catch (error) {
      enqueueSnackbar("Das Aus-/Abwählen des Projekts ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
      console.log("project de-/assignment failed: ", error);
    }
  };

  return (
    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {
        activeProjects.length > 0 ? (
          activeProjects.map(({ projectNumber }, idx) => {
            return (
              <ListItem
                key={idx}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(projectNumber)}
                    checked={checked.indexOf(projectNumber) !== -1}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary={projectNumber} />
                </ListItemButton>
              </ListItem>
            );
          })
        ) : (
          <Typography>
            Keine aktiven Projekte vorhanden
          </Typography>
        )
        
      }
  </List>
  );
}

export default UserAccordionProjectsList;