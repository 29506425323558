import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectEnhancedUser } from "../../store/user/user.selector";

import LoadingToRedirect from '../../components/loading/loading-to-redirect.component';

const AuthRouteAdmin = () => {
    const currentUser = useSelector(selectEnhancedUser);

    return currentUser && currentUser.admin ? <Outlet/> : <LoadingToRedirect redirectTo="/user/diary-overview" />;
};

export default AuthRouteAdmin;