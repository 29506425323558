import { DIARY_ACTION_TYPES } from "./diary.types";

const INITIAL_STATE = { 
    started: false,
    reset: false,
    currentStep: 0,
    projectData: null,
    siteLatLong: null,
    weatherDataRequested: false,
    weatherData: null,
    employees: {
        craftsmen: [],
        apprentices: [],
        thirdParties: [],
    },
    results: {
        summary: '',
        comments: '',
        specialEvents: '',
        images: [],
    },
    loading: false,
};

export const diaryReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case DIARY_ACTION_TYPES.RESET:
            return { ...state, reset: true };

        case DIARY_ACTION_TYPES.SET_LOADING:
            return { ...state, loading: payload };

        case DIARY_ACTION_TYPES.PREVIOUS_STEP:
            return { ...state, currentStep: state.currentStep - 1 };

        case DIARY_ACTION_TYPES.NEXT_STEP:
            return { ...state, currentStep: state.currentStep + 1 };

        case DIARY_ACTION_TYPES.SET_PROJECT:
            return { ...INITIAL_STATE, started: true, currentStep: 0, ...payload };

        case DIARY_ACTION_TYPES.SET_PROJECT_DATA:
            return { ...state, projectData: { ...state.projectData, ...payload }};

        case DIARY_ACTION_TYPES.SET_WEATHER_DATA:
            return { ...state, weatherDataRequested: true, weatherData: payload };

        case DIARY_ACTION_TYPES.SET_EMPLOYEES:
            return { ...state, employees: payload };

        case DIARY_ACTION_TYPES.SET_RESULTS:
            return { ...state, results: payload };

        default:
            return state;
    }
}