import { APPRENTICES_ACTION_TYPES } from "./apprentices.types";

const INITIAL_STATE = {
    apprentices: [],
}

export const apprenticesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case APPRENTICES_ACTION_TYPES.SET_APPRENTICES:
            return { ...state, apprentices : payload };

        default:
            return state;
    }
}