import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LoadingIndicator = ({ open, text = "Lädt..." }) => {
    return (
        <Dialog
            open={open}
            handleClose={null}    
        >
            <DialogContent>
                <Box
                    minHeight="150px" 
                    minWidth="300px"
                    sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}} 
                    spacing={2}
                >
                    <CircularProgress disableShrink />
                    <Typography fontWeight="bold" mt={3}>
                        {text}
                    </Typography>
                </Box>  
            </DialogContent>
          
        </Dialog>
    )
}

export default LoadingIndicator;