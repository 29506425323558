import { createSelector } from "reselect";
import { selectCurrentUserProjects } from "../user/user.selector";

const selectDiaryOverview = (state) => state.diaryOverview;  

export const selectDiaryOverviewProject = createSelector(
    [selectDiaryOverview, selectCurrentUserProjects],
    (diaryOverview, userProjects) => {
        return userProjects.find(userProject => userProject.projectNumber === diaryOverview.projectNumber);
    }
);

export const selectDiaryOverviewNextDiaryNumber = createSelector(
    [selectDiaryOverviewProject],
    diaryOverviewProject => {
        let diaryNumber = 0;

        diaryOverviewProject.diaries.forEach(diary => {
            if(diary.diaryNumber > diaryNumber)
                diaryNumber = diary.diaryNumber;
        })

        return diaryNumber + 1;
    }
)