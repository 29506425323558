import { useSelector } from 'react-redux';

import { selectCraftsmen } from '../../store/craftsmen/craftsmen.selector';
import { selectApprentices } from '../../store/apprentices/apprentices.selector';
import { selectThirdParties } from '../../store/third-parties/third-parties.selector';

import { FIREBASE_COLLECTION_NAMES } from '../../utils/firebase/firebase-firestore.utils';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import EmployeesList from'../../components/employees-list/employees-list.component';

const Employees = () => {
  const craftsmen = useSelector(selectCraftsmen);
  const apprentices = useSelector(selectApprentices);
  const thirdParties = useSelector(selectThirdParties);

  return (
    <Container>
      <Stack marginY={2} spacing={4}>
        <EmployeesList 
          collectionName={FIREBASE_COLLECTION_NAMES.CRAFTSMEN}
          employees={craftsmen} 
          title={"Gesellen"} 
          newDialogTitle={"Neuer Geselle"}
          newButtonText={"Neuer Geselle"}
          editDialogTitle={"Geselle bearbeiten"}
          dialogLabelName={"Geselle"}

        />
        <EmployeesList 
          collectionName={FIREBASE_COLLECTION_NAMES.APPRENTICES}
          employees={apprentices} 
          title={"Auszubildene"} 
          newDialogTitle={"Neuer Auszubildene"}
          newButtonText={"Neuer Auszubildene"}
          editDialogTitle={"Auszubildene bearbeiten"}
          dialogLabelName={"Auszubildene"}
        />
        <EmployeesList 
          collectionName={FIREBASE_COLLECTION_NAMES.THIRD_PARTIES}
          employees={thirdParties} 
          title={"Fremdfirmen"} 
          newDialogTitle={"Neue Fremdfirma"}
          newButtonText={"Neue Fremdfirma"}
          editDialogTitle={"Fremdfirma bearbeiten"}
          dialogLabelName={"Fremdfirma"}
        />
      </Stack>
    </Container>
  );
}

export default Employees;