import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectEnhancedUser } from '../../store/user/user.selector';
import { selectDiaryReset, selectDiaryStarted } from '../../store/diary/diary.selector';
import { selectDiaryOverviewProject } from '../../store/diary-overview/diary-overview.selector';

import { setDiaryProject } from '../../store/diary/diary.action';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import DiaryOverviewProjectSelection from '../../components/diary-overview-project-selection/diary-overview-project-selection-component';
import ConfirmDeclineDialog from '../../components/confirm-decline-dialog/confirm-decline-dialog.component';
import DiaryList from '../../components/diary-list/diary-list.component';

export const DiaryOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const currentUser = useSelector(selectEnhancedUser);
  const project = useSelector(selectDiaryOverviewProject);
  const started = useSelector(selectDiaryStarted);
  const reset = useSelector(selectDiaryReset);
  
  const [dialogOpen, setDialogOpen] = useState(false); 

  const createOrContinueNewDiary = () => {
    if(!reset && started)
      setDialogOpen(true);
    else
      createNewDiary();
  }

  const continueNewDiary = () => {
    navigate("/user/diary-new");
  }

  const createNewDiary = () => {
    dispatch(setDiaryProject({ ...project, siteManager: currentUser.displayName }));
    navigate("/user/diary-new");
  }

  return (
    <Container>
        <Stack marginY={2} spacing={4}>
          <DiaryOverviewProjectSelection /> 
          {
            project ? (
              <Box display="flex" justifyContent="center">
                <Button variant="contained" color="secondary" onClick={createOrContinueNewDiary}>
                    Neues Bautagebuch
                </Button>  
              </Box>
            ) : null
          }
          
          <DiaryList project={project}/>
        </Stack>
        <ConfirmDeclineDialog
          open={dialogOpen}
          dialogTitle="Vorheriges Bautagebuch fortführen"
          dialogText="Sie haben bereits ein Bautagebuch begonnen. Wollen Sie dieses fortführen?"
          confirmText="Neu anfangen"
          declineText="Fortführen"
          handleClose={() => setDialogOpen(false)}
          handleConfirmActiom={createNewDiary}
          handleDeclineAction={continueNewDiary}
        />
    </Container>
  );
}

export default DiaryOverview;