import {  useSelector } from 'react-redux';

import { selectDiaryCurrentStep } from '../../store/diary/diary.selector';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import DiaryNewPage1 from '../../components/diary-new-pages/diary-new-page-1.component';
import DiaryNewPage2 from '../../components/diary-new-pages/diary-new-page-2.component';
import DiaryNewPage3 from '../../components/diary-new-pages/diary-new-page-3.component';
import DiaryNewPage4 from '../../components/diary-new-pages/diary-new-page-4.component';
import DiaryNewPage5 from '../../components/diary-new-pages/diary-new-page-5.component';

const steps = [
  'Projektdaten',
  'Wetter',
  'Monteure',
  'Ergebnisse',
  'Kontrolle & Unterschrift',
]

const DiaryNew = () => {
  const currentStep = useSelector(selectDiaryCurrentStep);

  return (
    <Container>
      <Box marginY={2}>
        {
          currentStep === 0 ? (
            <DiaryNewPage1
              steps={steps}
              activeStep={currentStep}
            />
          ) : null
        }
        {
          currentStep === 1 ? (
            <DiaryNewPage2
              steps={steps}
              activeStep={currentStep}
            />
          ) : null
        }
        {
          currentStep === 2 ? (
            <DiaryNewPage3
              steps={steps}
              activeStep={currentStep}
            />
          ) : null
        }
        {
          currentStep === 3 ? (
            <DiaryNewPage4
              steps={steps}
              activeStep={currentStep}
            />
          ) : null
        }
        {
          currentStep === 4 ? (
            <DiaryNewPage5
              steps={steps}
              activeStep={currentStep}
            />
          ) : null
        }
      </Box>
    </Container>
  );
}

export default DiaryNew;