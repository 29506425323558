import { createSelector } from 'reselect';
import { selectCurrentUserProjects } from '../user/user.selector';


const selectDiary = (state) => state.diary;

export const selectDiaryStarted = createSelector(
    [selectDiary],
    diary => diary.started
)

export const selectDiaryReset = createSelector(
    [selectDiary],
    diary => diary.reset
)

export const selectDiaryLoading = createSelector(
    [selectDiary],
    diary => diary.loading
)

export const selectDiaryCurrentStep = createSelector(
    [selectDiary],
    diary => diary.currentStep
)

export const selectDiaryProjectData = createSelector(
    [selectDiary],
    diary => diary.projectData
);

export const selectDiaryProject = createSelector(
    [selectDiaryProjectData, selectCurrentUserProjects],
    (projectData, userProjects) => userProjects.find(project => project.projectNumber === projectData.projectNumber)
) 

export const selectDiarySiteLatLong = createSelector(
    [selectDiary],
    diary => diary.siteLatLong
);

export const selectDiaryWeatherDataRequested = createSelector(
    [selectDiary],
    diary => diary.weatherDataRequested
)

export const selectDiaryWeatherData = createSelector(
    [selectDiary],
    diary => diary.weatherData
)

export const selectDiaryEmployees = createSelector(
    [selectDiary],
    diary => diary.employees
) 

export const selectDiaryResults = createSelector(
    [selectDiary],
    diary => diary.results
)