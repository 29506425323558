import { THIRD_PARTIES_ACTION_TYPES } from "./third-parties.types";

const INITIAL_STATE = {
    thirdParties: [],
}

export const thirdPartiesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case THIRD_PARTIES_ACTION_TYPES.SET_THIRD_PARTIES:
            return { ...state, thirdParties: payload };

        default:
            return state;
    }
}