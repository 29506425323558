import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyAHLOca6UtWhpUQ5BNgOEvl5GQSmLvprL0",
    authDomain: "wegener-bautagebuch.firebaseapp.com",
    projectId: "wegener-bautagebuch",
    storageBucket: "wegener-bautagebuch.appspot.com",
    messagingSenderId: "57617822067",
    appId: "1:57617822067:web:e76ff9dc14ce8a450c9506"
};

export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
auth.languageCode = 'de';
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp);