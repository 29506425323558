import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import * as Yup from 'yup';

import { previousDiaryPage, resetDiary } from "../../store/diary/diary.action";

import { selectEnhancedUser } from "../../store/user/user.selector";
import { 
    selectDiaryEmployees,
    selectDiaryProject,
    selectDiaryProjectData, 
    selectDiaryResults, 
    selectDiaryWeatherData 
} from "../../store/diary/diary.selector";
import { selectDiaryOverviewNextDiaryNumber } from "../../store/diary-overview/diary-overview.selector";

import { getCurrentDate } from "../../utils/date";
import { createPdfAsBlob } from "../../utils/pdf";
import { uploadFile } from "../../utils/firebase/firebase-storage.utils";
import { addEntry, FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";
import { generateUID } from "../../utils/uid";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import StepperFooter from "../stepper/stepper-footer.component";
import StepperHeader from "../stepper/stepper-header.component";
import FormikCheckbox from "../formik-checkbox/formik-checkbox.component";
import FileImage from "../file-image/file-image.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikSignature from "../formik-signature/formik-signature.component";
import LoadingIndicator from "../loading/loading-indicator.component";

const FORM_VALIDATION = Yup.object().shape({
    signatureDate: Yup.string().required("Erforderlich"),
    signatureLocation: Yup.string().required("Erforderlich"),
    signed: Yup.bool().oneOf([true], "Unterschrift ist erforderlich"),
    confirmation: Yup.bool().oneOf([true], "Bestätigen Sie die Richtigkeit der Daten"),
}); 

const DiaryNewPage5 = ({ steps, activeStep }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    
    const { signatureUrl } = useSelector(selectEnhancedUser);
    const diaryNumber = useSelector(selectDiaryOverviewNextDiaryNumber);
    const diaryProject = useSelector(selectDiaryProject);
    const projectData = useSelector(selectDiaryProjectData);
    const weather = useSelector(selectDiaryWeatherData);
    const employees = useSelector(selectDiaryEmployees);
    const results = useSelector(selectDiaryResults);

    const openAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleBack = () => { 
        dispatch(previousDiaryPage());
    }
    
    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);

            const { signatureDate, signatureLocation } = values;
            const folderPath = `/projects/${projectData.projectNumber}/${diaryNumber} - ${projectData.date}/`

            const pdfBlob = await createPdfAsBlob(
                projectData,
                weather,
                employees,
                results,
                signatureDate,
                signatureLocation,
                signatureUrl
            );            

            const pdfUrl = await uploadFile(
                `${folderPath}/Bautagebuch Nr. ${diaryNumber}.pdf`,
                pdfBlob
            );
      
            await addEntry(
                FIREBASE_COLLECTION_NAMES.PROJECTS,
                projectData.projectNumber,
                {
                    ...diaryProject,
                    diaries: [ 
                        ...diaryProject.diaries, 
                        {
                            createdAt: new Date(),
                            date: projectData.date,
                            diaryNumber,
                            pdfUrl
                        }
                    ]
                }
            );    

            dispatch(resetDiary());
            navigate("/user/diary-overview"); 
        } catch(error) {
            enqueueSnackbar("Das Hochladen des Bautagebuches ist fehlgeschlagen. Versuchen Sie es nochmal.", { variant: 'error' });
            console.log("diary upload failed: ", error);
        } finally {
            setIsLoading(false);
        }   
    }    

    return (
        <Fragment>
            <Formik
                initialValues={{
                    signatureDate: getCurrentDate(),
                    signatureLocation: '',
                    signed: false,
                    confirmation: false,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
            >
                <Form>
                    <StepperHeader
                        activeStep={activeStep}
                        steps={steps}
                    />  
                        <Grid container spacing={2}>
                            {/* Projektdaten */}
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel1'} onChange={openAccordion('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            Projektdaten
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container padding={2} spacing={1}>
                                            {/* Date */}
                                            <Grid item xs={12} md={3}>
                                                <Typography fontWeight="bold">
                                                    Datum
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Typography  >
                                                    {projectData.date}
                                                </Typography>
                                            </Grid>
                                            {/* CW */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Kalenderwoche
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.week}
                                                </Typography>
                                            </Grid>
                                            {/* Bau-Tagesbericht-Nr. */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Bau-Tagesbericht-Nr.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {diaryNumber}
                                                </Typography>
                                            </Grid>
                                            {/* Leistungsempfänger */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Leistungsempfänger
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.beneficiary}
                                                </Typography>
                                            </Grid>
                                            {/* Bauleiter Wegener */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Bauleiter Wegener
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.siteManager}
                                                </Typography>
                                            </Grid>
                                            {/* Bauleiter Auftraggeber */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Bauleiter Auftraggeber
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.siteManagerClient}
                                                </Typography>
                                            </Grid>
                                            {/* Projektnummer Wegener */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Projektnummer Wegener
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.projectNumber}
                                                </Typography>
                                            </Grid>
                                            {/* Baustelle, Anschrift */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Baustelle, Anschrift
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {`${projectData.siteStreetName} ${projectData.siteHouseNumber}, ${projectData.siteZipCode} ${projectData.siteCityName}, ${projectData.siteCountry}`}
                                                </Typography>
                                            </Grid>
                                            {/* Projektnummer Auftraggeber */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Projektnummer Auftraggeber
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.projectNumberClient}
                                                </Typography>
                                            </Grid>
                                            {/* Gewerk */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Gewerk
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {projectData.craft}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* Weather */}
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel2'} onChange={openAccordion('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>Wetter</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container padding={2} spacing={1}>
                                            {/* Sun */}
                                            <Grid item xs={12} md={3}>
                                                <Typography fontWeight="bold">
                                                    Sonne
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Typography  >
                                                    {weather.sun ? "Ja" : "Nein"}
                                                </Typography>
                                            </Grid>
                                            {/* Rain */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Regen
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {weather.rain ? "Ja" : "Nein"}
                                                </Typography>
                                            </Grid>
                                            {/* Frost */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Frost
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {weather.frost ? "Ja" : "Nein"}
                                                </Typography>
                                            </Grid>
                                            {/* Wind */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Wind
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {weather.wind ? "Ja" : "Nein"}
                                                </Typography>
                                            </Grid>
                                            {/* Snow */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Schnee
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {weather.snow ? "Ja" : "Nein"}
                                                </Typography>
                                            </Grid>
                                            {/* Temperature */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">  
                                                    Temperatur
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography>
                                                    {`${weather.temperature} °C`}
                                                </Typography>
                                            </Grid>
                                            {/* Others */}
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Sonstiges
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {weather.otherInformation.length > 0 ? weather.otherInformation : "Keine Angabe"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* Employees */}
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel3'} onChange={openAccordion('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>Monteure</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container padding={2} spacing={1}>
                                            {/* Craftsmen */}
                                            <Grid item xs={12} sm={3}>
                                                <Typography fontWeight="bold">
                                                    Gesellen
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Typography  >
                                                    {employees.craftsmen.length > 0 ? employees.craftsmen.join(", ") : "Keine Angabe"}
                                                </Typography>
                                            </Grid>
                                            {/* Apprentices */}
                                            <Grid item xs={12} sm={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Auszubildene
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {employees.apprentices.length > 0 ? employees.apprentices.join(", ") : "Keine Angabe"}
                                                </Typography>
                                            </Grid>
                                            {/* Third Parties */}
                                            <Grid item xs={12} sm={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Fremdfirmen
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {employees.thirdParties.length > 0 ? employees.thirdParties.join(", ") : "Keine Angabe"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* Results */}
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel4'} onChange={openAccordion('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>Ergebnisse</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container padding={2} spacing={1}>
                                            <Grid item xs={12} md={3}>
                                                <Typography fontWeight="bold">
                                                    Leistungsergebnisse/-änderungen
                                                </Typography>                  
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Typography  >
                                                    {results.summary.length > 0 ? results.summary : "Keine Angabe"} 
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Bemerkungen
                                                </Typography>                  
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {results.comments.length > 0 ? results.comments : "Keine Angabe"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Besondere Vorkommnisse
                                                </Typography>                  
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                <Typography  >
                                                    {results.specialEvents.length > 0 ? results.specialEvents : "Keine Angabe"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3} mt={2}>
                                                <Typography fontWeight="bold">
                                                    Bilder
                                                </Typography>                  
                                            </Grid>
                                            <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                                {
                                                    results.images.length > 0 ? (
                                                        <Grid container spacing={1}>
                                                            {
                                                                results.images.map((image, idx) => {
                                                                    return (
                                                                        <Grid key={idx} item xs={12} md={6}>
                                                                            <FileImage image={image} displayHeight={211} />
                                                                        </Grid>
                                                                    );
                                                                })
                                                            }
                                                            
                                                        </Grid>
                                                    ) : (
                                                        <Typography>
                                                            Keine Bilder
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>  
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* Signature */}
                            <Grid item xs={12}>
                                <Grid container padding={2} spacing={1}>
                                    {/* Signature Date */}
                                    <Grid item xs={12} md={3}>
                                        <Typography fontWeight="bold">
                                            Datum
                                        </Typography>                  
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <FormikTextfield name="signatureDate" size="small" />
                                    </Grid>
                                    {/* Signature Location */}
                                    <Grid item xs={12} md={3} mt={2}>
                                        <Typography fontWeight="bold">
                                            Ort
                                        </Typography>                  
                                    </Grid>
                                    <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                        <FormikTextfield name="signatureLocation" size="small"  />
                                    </Grid>
                                    {/* Signature */}
                                    <Grid item xs={12} md={3} mt={2}>
                                        <Typography fontWeight="bold">
                                            Unterschrift
                                        </Typography>                  
                                    </Grid>
                                    <Grid item xs={12} md={9} mt={{ xs: 0, md: 2 }}>
                                        <FormikSignature name="signed" signatureSrc={signatureUrl} />
                                    </Grid>
                                    {/* Confirmation */}
                                    <Grid item xs={12} mt={2}>
                                        <Stack direction="row" spacing={1} display="flex" alignItems="center">
                                            <FormikCheckbox name="confirmation" />
                                            <Typography>
                                                Ich bestätige, dass ich meine Eingaben auf Richtigkeit überprüft habe.
                                            </Typography>
                                        </Stack>                            
                                    </Grid>
                                </Grid>                                                     
                            </Grid>
                        </Grid>
                    <StepperFooter 
                        steps={steps}
                        activeStep={activeStep} 
                        handleBack={handleBack} 
                        nextText="Fertig"
                        isLoading={isLoading}
                    />             
                </Form>
            </Formik> 
            <LoadingIndicator
                open={isLoading}
                text="Bautagebuch wird hochgeladen"
            />
        </Fragment>
    );
}

export default DiaryNewPage5;