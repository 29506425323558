import { Fragment } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import FormikButton from '../formik-button/formik-button.component';

const StepperFooter = ({ 
  steps,
  activeStep, 
  handleBack, 
  nextText = "Weiter",
}) => {
  return (
    <Fragment >
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'row', pt: 2 }}>
        <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
        >
            Zurück
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <FormikButton>
          {nextText}
        </FormikButton>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none'} }}>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          nextButton={
            <FormikButton 
              size="small" 
              disabled={activeStep === steps.length}
            >
              {nextText}
              <KeyboardArrowRight />
            </FormikButton>
          }
          backButton={
            <Button 
              size="small" 
              onClick={handleBack} 
              disabled={activeStep === 0}
            >
                <KeyboardArrowLeft />
                Zurück
            </Button>
          }
        />
      </Box>
    </Fragment>    
  )
}

export default StepperFooter;