import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setDiaryOverviewProjectNumber } from "../../store/diary-overview/diary-overview.action";
import { selectCurrentUserProjects } from "../../store/user/user.selector";
import { selectDiaryOverviewProject } from "../../store/diary-overview/diary-overview.selector";

import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Stack } from "@mui/material";

const DiaryOverviewProjectSelection = () => {
    const dispatch = useDispatch();
    const userProjects = useSelector(selectCurrentUserProjects);
    const project = useSelector(selectDiaryOverviewProject);

    useEffect(() => {
        // If a project is already set, do nothing
        if(project) { return; }

        if(userProjects && userProjects.length > 0) {
            dispatch(setDiaryOverviewProjectNumber(userProjects[0].projectNumber));
        }
    }, [project]);

    const handleChange = (event) => {
        dispatch(setDiaryOverviewProjectNumber(event.target.value));
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h6" textAlign="center">
                Bautagebücher
            </Typography>
            {
                project && userProjects && userProjects.length > 0 ? (
                    <Select
                        fullWidth={true}
                        value={project.projectNumber}
                        onChange={handleChange}
                    >
                        {
                            userProjects.map(({ projectNumber }, idx) => {
                                return (
                                    <MenuItem key={idx} value={projectNumber}>
                                        {projectNumber}
                                    </MenuItem>
                                ); 
                            })
                        }
                    </Select> 
                ) : (
                    <Typography textAlign="center">
                        Ihnen sind keine Projekte zugewiesen
                    </Typography>
                )
            }
        </Stack>
  )
}

export default DiaryOverviewProjectSelection;
