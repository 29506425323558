import { 
    deleteObject, 
    getDownloadURL, 
    getMetadata, 
    listAll, 
    ref, 
    uploadBytesResumable 
} from "firebase/storage";

import { blobToBase64 } from "../image";
import { storage } from "./firebase.utils";

export const uploadFile = (filePath, file, onProgress = null) => {
    return new Promise((resolve, _) => {
        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        uploadTask.on(
            "state_changed", 
            onProgress, 
            (error) => console.log("file upload encoutered an error: ", error),
            async () => {
                const downloadUrl = await getUrl(filePath);
                resolve(downloadUrl);
            }
        );
    });
}

export const getUrl = async (filePath) => {
    try {
        const storageRef = ref(storage, filePath);
        return await getDownloadURL(storageRef);
    } catch(error) {
        console.log(`Error while getting the download url for ${filePath}: `, error);
    }
}

export const getFileMetadata = async (url) => {
    try {
        const storageRef = ref(storage, url);
        return await getMetadata(storageRef);
    } catch (error) {
        console.log("Error encountered while fetching metadata: ", error);
    }
}

export const downloadFile = (url) => {
    return new Promise((resolve, _) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
        const blob = xhr.response;
        resolve(blob);
        };
        xhr.open('GET', url);
        xhr.send();
    });    
}

export const downloadImages = async (images) => {
    const base64Images = [];
    for(let i = 0; i < images.length; i++) {
        const blob = await downloadFile(images[i]);
        const base64 = await blobToBase64(blob);
        base64Images.push(base64);
    } 
    return base64Images;
}

export const deleteFolder = async (folderPath) => {
    try {
        const folderRef = ref(storage, folderPath);
        await deleteSubFolder(folderRef);
    } catch(error) {
        console.log(`Error deleting folder ${folderPath}: `, error);
    }
}

const deleteSubFolder = async (subFolderRef) => {
    const fileRefs = await listAll(subFolderRef);

    fileRefs.prefixes.forEach(async (subFolderRef) => {
        await deleteSubFolder(subFolderRef);
    });

    fileRefs.items.forEach(async (fileRef) => {
        await deleteObject(fileRef);
    });
}

export const deleteFile = async (filePath) => {
    try {
        const storageRef = ref(storage, filePath);
        await deleteObject(storageRef);
        
    } catch(error) {
        console.log(`Error deleting file ${filePath}: `, error);
    }    
} 